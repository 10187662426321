import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useLazyQuery, ServerError } from '@apollo/client'
import PhoneInput from 'react-phone-number-input'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '../../../../_metronic/partials/alert'
import { trim } from '../../../../_metronic/helpers'
import query from '../../../../setup/graphql/query'
import { fetchStartWithForward, fetchStop } from '../../../../redux/actions/common'
import { setLoanSubStep, setLoanApplication } from '../redux/actions'
import { MaintenanceModeBanner } from '../../../../_metronic/helpers/components/MaintenanceModeBanner'

const Step1Sub0: FC<any> = ({ sendOTP, setMobileNo }: any) => {
  const dispatch = useDispatch()

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')

  const handleCreateApplication = () => {
    dispatch(setLoanSubStep(1))
  }

  const initialValues = {
    mobileNumber: '',
  }

  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .test(
        'mobileValidation',
        'Invalid Mobile number, kindly recheck the entered number',
        (value: any) => {
          /* if (/^\+65(8|9)\d{7}$/g.test(value) || /^\+977\d{10}$/g.test(value)) {
									return true
					}
					return false */
          return true
        }
      )
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const { mobileNumber } = trim(values)
      setMobileNo(mobileNumber)
      dispatch(fetchStartWithForward())
      sendOTP({
        variables: {
          mobileNo: mobileNumber,
          isApplicantOrShareholder: true,
        },
      })
      setSubmitting(false)
    },
  })

  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <div className='cardNew'>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='row mb-10'>
            <div className='col-md-12'>
              <label htmlFor='' className='input-field-label required'>
                Mobile Number
              </label>
              <div className='col-sm-12'>
                <PhoneInput
                  id='mobileNumber'
                  international
                  defaultCountry='SG'
                  // countryCallingCodeEditable={false}
                  value={formik.values.mobileNumber}
                  onChange={(value: any) => formik.setFieldValue('mobileNumber', value)}
                  placeholder='Enter phone number'
                  className='int-phone-input'
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className='text-danger mt-2'>{formik.errors.mobileNumber}</div>
                )}
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between'>
            <button
              type='button'
              className='button button-primary me-3'
              onClick={() => handleCreateApplication()}
            >
              Create new application
            </button>
            <button type='submit' className='button button-primary'>
              Send OTP
            </button>
          </div>
        </form>
      </div>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export { Step1Sub0 }
