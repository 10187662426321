/* eslint-disable react-hooks/exhaustive-deps */
import { ServerError, useLazyQuery, useMutation } from '@apollo/client'
import { parse } from 'query-string'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import 'react-circular-progressbar/dist/styles.css'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { usePrevious } from '../../../_metronic/helpers/customHooks'
import Alert from '../../../_metronic/partials/alert'
import { fetchStop } from '../../../redux/actions/common'
import mutations from '../../../setup/graphql/mutations'
import query from '../../../setup/graphql/query'
import {
  setDecisionMaker,
  setDirector,
  setGuarantor,
  setLoanApplication,
  setLoanEligibility,
  setLoanStep,
  setLoanSubStep,
  setPartnerDetails,
  setSingPassInfo,
} from './redux/actions'
import axios from 'axios'
import ReactPixel from 'react-facebook-pixel'
import { getIntegrationURLFromENV, handleSettingEnv } from '../../../utils/getIntegrationURLFromENV'
import { Step1, Step2, Step3, Step3Sub1, Step4 } from './steps'
import './LoanApplication.scss'
import { CheckCircleIcon } from '../../../svg/CheckCircleIcon'
import { Step1Icon } from '../../../svg/step1'
import { Step2Icon } from '../../../svg/step2'
import { Step3Icon } from '../../../svg/step3'
import { Step4Icon } from '../../../svg/step4'
import { Step5Icon } from '../../../svg/step5'

const LoanApplication: FC = () => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const history: any = useHistory()
  const { pathname } = useLocation()
  const partnerDetails = useSelector((state: any) => state.loan.partner)
  // let mainStep = 0, mainSubStep = 0;

  const { id } = params
  const searchQuery: any = parse(history.location.search)

  /**
   * Setting ENV & PartnerId
   */
  useEffect(() => {
    handleSettingEnv(searchQuery?.env)
  }, [])

  useEffect(() => {
    if (partnerDetails == null) {
      const localId = localStorage.getItem('partnerId')
      getPartnerDetails(searchQuery?.partner || localId)
    }
  }, [])

  const getPartnerDetails = async (partnerId: string) => {
    const env = localStorage.getItem('env')
    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)

    const partnerData = await axios.get(`${API_URL}ext/api/businesspartner/${partnerId || ''}`)

    if (partnerData.data && partnerData.status === 200) {
      localStorage.setItem('partnerId', partnerData.data?.id)
      localStorage.setItem('partnerDetails', JSON.stringify(partnerData.data))
      dispatch(setPartnerDetails(partnerData.data))
    }
  }

  // if(searchQuery.length>0) {
  //   mainStep = Number(searchQuery.step.split('.')[0]);
  //   mainSubStep = Number(searchQuery.step.split('.')[1]);
  // }

  const prevStep: any = usePrevious(searchQuery)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [progress, setProgress] = useState<any>(0)

  const [getApplication, onSuccessGetApplication] = useLazyQuery(query.GET_APPLICATION)
  const [updateApplication, onSuccessUpdateApplicaton] = useMutation(mutations.UPDATE_APPLICATION)
  const loanApplication = useSelector((state: any) => state.loan.loanApplication)
  const loanStep = useSelector((state: any) => state.loan.loanStep)
  const loanSubStep = useSelector((state: any) => state.loan.loanSubStep)
  const status = useSelector((state: any) => state.loan.loanApplicationStatus)
  const singpassInfo = useSelector((state: any) => state.loan.singpass)
  const entityPerson = useSelector((state: any) => state.loan.entityPerson)
  const forwardWithButton = useSelector((state: any) => state.common.forwardWithButton)
  const companyVerificationMethod = useSelector(
    (state: any) => state.coApplicant.companyVerificationMethod
  )

  const pageRedirect = (loanApplication: any) => {
    if (history.location.search.length > 0) {
      const { applicationCompleteStep } = loanApplication
      const searchQuery: any = parse(history.location.search)
      const mainStep = Number(searchQuery.step.split('.')[0])
      const mainSubStep = Number(searchQuery.step.split('.')[1])

      if (applicationCompleteStep === 5) {
        if (mainStep !== applicationCompleteStep) {
          dispatch(setLoanStep(mainStep))
        } else {
          if (entityPerson.entity !== null || singpassInfo !== null) {
            dispatch(setLoanStep(applicationCompleteStep))
          } else {
            dispatch(setLoanStep(2))
            history.replace(`/application/${loanApplication.id}?step=2`)
          }
        }
      } else if (applicationCompleteStep === 4) {
        if (entityPerson.entity !== null || singpassInfo !== null) {
          dispatch(setLoanStep(mainStep))
        } else {
          dispatch(setLoanStep(2))
          history.replace(`/application/${loanApplication.id}?step=2`)
        }
      } else if (applicationCompleteStep === 3) {
        if (mainStep !== applicationCompleteStep) {
          dispatch(setLoanStep(mainStep))
        } else {
          if (entityPerson.entity !== null) {
            dispatch(setLoanStep(applicationCompleteStep))
          } else {
            dispatch(setLoanStep(2))
            history.replace(`/application/${loanApplication.id}?step=2`)
          }
        }
      } else if (applicationCompleteStep === 2) {
        if (mainStep === applicationCompleteStep) {
          dispatch(setLoanStep(2))
        } else {
          if (mainStep === 3 && companyVerificationMethod === 1 && entityPerson.entity == null) {
            history.push(`/application/${loanApplication.id}?step=2`)
          } else {
            dispatch(setLoanStep(mainStep))
            dispatch(setLoanSubStep(mainSubStep))
            history.push(
              `/application/${loanApplication.id}?step=${mainStep}${
                mainSubStep > 0 ? `.${mainSubStep}` : ''
              }`
            )
          }
          /*           if(entityPerson.entity !== null){
            dispatch(setLoanStep(mainStep));
            dispatch(setLoanSubStep(mainSubStep));
            history.push(`/application/${loanApplication.id}?step=${mainStep}${mainSubStep>0 ? `.${mainSubStep}` : ''}`);
          } else {
            dispatch(setLoanStep(2));
            history.replace(`/application/${loanApplication.id}?step=2`);
          } */
        }
      } else if (applicationCompleteStep < 2) {
        if (mainStep < applicationCompleteStep) {
          dispatch(setLoanStep(mainStep))
          dispatch(setLoanSubStep(mainSubStep))
        } else {
          // const currentStep = loanApplication.applicationCompleteStep;
          // const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
          // const subStep = Number(currentStep.toString().split('.')[1]);

          // dispatch(setLoanStep(step));
          // dispatch(setLoanSubStep(subStep));
          // history.replace(`/application/${loanApplication.id}?step=${step}.${subStep}`);
          dispatch(setLoanStep(mainStep))
        }
      } else {
        dispatch(setLoanStep(mainStep))
        dispatch(setLoanSubStep(mainSubStep))
      }
    } else {
      dispatch(setLoanStep(1))
      dispatch(setLoanSubStep(2))
      history.replace(`/application/${loanApplication.id}`)
    }
  }

  useEffect(() => {
    if (searchQuery.step !== '2') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [pathname, searchQuery])

  useEffect(() => {
    if (prevStep && Object.keys(prevStep).length > 0 && Object.keys(searchQuery).length > 0) {
      if (prevStep.step !== searchQuery.step && loanApplication && !forwardWithButton) {
        pageRedirect(loanApplication)
      }
    }

    if (Object.keys(searchQuery).length === 0 && id && status === 1) {
      dispatch(setLoanStep(1))
      dispatch(setLoanSubStep(2))
    }
  }, [searchQuery])

  useEffect(() => {
    const loanApplicationId = localStorage.getItem('loanApplicationId')
    /* if(id) {
      if(loanApplication === null && (loanSubStep > 2 || entityPerson) ) {
      // if(loanApplication === null) {
        getApplication({
          variables: {
            appId: id || loanApplicationId
          }
        })
      } else {
        if(loanSubStep === 0){
          history.replace('/application/type');

        }
      }
    } else if(status > 0) {
      const loanSubStep = status === 1 ? 1 : 0;
      dispatch(setLoanStep(1));
      dispatch(setLoanSubStep(loanSubStep));
    } else {
      history.replace('/application/type');
    } */

    if (id) {
      if (loanApplication === null) {
        getApplication({
          variables: {
            appId: id || loanApplicationId,
          },
        })
      }
    } else {
      const loanSubStep = status === 1 ? 1 : 0
      dispatch(setLoanStep(1))
      dispatch(setLoanSubStep(loanSubStep))
    }
  }, [id])

  useEffect(() => {
    if (loanStep > 0) {
      const progress = progressPercentageCalculate()
      setProgress(progress)
    }
  }, [loanStep, loanSubStep])

  useEffect(() => {
    if (onSuccessGetApplication.called && !onSuccessGetApplication.loading) {
      dispatch(fetchStop())
      if (onSuccessGetApplication.data) {
        const loanApplication = onSuccessGetApplication.data.getLatestApplication
        // const currentStep = loanApplication.applicationCompleteStep;
        // const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
        // const step = currentStep < 1.4 ? Math.floor(currentStep) : Math.floor(currentStep)+1;

        if (loanApplication !== null) {
          const loanApplicationId = localStorage.getItem('loanApplicationId')
          // const loanEligibility = loanApplication.loanEligibility.length > 0 ? loanApplication.loanEligibility[0] : null;
          const {
            applicationCompleteStep,
            loanEligibility,
            shareholderKycStatus,
            externalDataStore,
          } = loanApplication
          const singpassInfo = externalDataStore.filter((o: any) => o.type === 'singpass')

          if (loanEligibility !== null) {
            dispatch(setLoanEligibility(loanEligibility))
          }

          if (shareholderKycStatus.length > 0) {
            const decisionMaker = shareholderKycStatus.filter((o: any) => o.isDecisionMaker)[0]
            const gurantors = shareholderKycStatus.filter((o: any) => o.isGuarantor)
            const directors = shareholderKycStatus.filter((o: any) => o.isDirector)

            dispatch(setGuarantor(gurantors))
            dispatch(setDirector(directors))
            dispatch(setDecisionMaker(decisionMaker ? decisionMaker : null))
          }

          if (singpassInfo.length > 0) {
            dispatch(setSingPassInfo(singpassInfo[0].data))
          }

          pageRedirect(loanApplication)

          /* if(history.location.search.length>0){
            const searchQuery:any = parse(history.location.search)
            const mainStep = Number(searchQuery.step.split('.')[0]);
            const mainSubStep = Number(searchQuery.step.split('.')[1]);

            if(applicationCompleteStep === 4) {

            } else if(applicationCompleteStep === 3) {

            } else if(applicationCompleteStep === 2) {
              if(mainStep === applicationCompleteStep) {
                dispatch(setLoanStep(2));
              } else {
                if(entityPerson){
                  dispatch(setLoanStep(mainStep));
                } else {
                  dispatch(setLoanStep(2));
                  history.replace(`/application/${loanApplication.id}?step=2`);
                }
              }

            } else if(applicationCompleteStep < 2) {
              if(mainStep < applicationCompleteStep) {
                dispatch(setLoanStep(mainStep));
                dispatch(setLoanSubStep(mainSubStep));
              } else {
                const currentStep = loanApplication.applicationCompleteStep;
                const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
                const subStep = Number(currentStep.toString().split('.')[1]);

                dispatch(setLoanStep(step));
                dispatch(setLoanSubStep(subStep));
                history.replace(`/application/${loanApplication.id}?step=${step}.${subStep}`);
              }
            } else {
              dispatch(setLoanStep(mainStep));
              dispatch(setLoanSubStep(mainSubStep));
            }

          } else {
            dispatch(setLoanStep(1));
            dispatch(setLoanSubStep(2));
            history.replace(`/application/${loanApplication.id || loanApplicationId}`);
          } */

          /* const currentStep = loanApplication.applicationCompleteStep;
          const currentSubStep = Number(currentStep.toString().split('.')[1]);

          const step = currentStep < 1.4 
            ? Math.floor(currentStep) 
            : entityPerson !== null ? 3 : Math.floor(currentStep)+1;
          const subStep = currentSubStep === 1 ? 3 : currentSubStep+1; */

          dispatch(setLoanApplication(loanApplication))
        } else {
          if (status === 1) {
            history.replace('/application/type')
          }
        }
      } else {
      }

      if (onSuccessGetApplication.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessGetApplication.loading])

  useEffect(() => {
    if (onSuccessUpdateApplicaton.called && !onSuccessUpdateApplicaton.loading) {
      // dispatch(fetchStop());
      if (onSuccessUpdateApplicaton.data) {
        const { updateApplication } = onSuccessUpdateApplicaton.data
        const application = { ...loanApplication, ...updateApplication }
        dispatch(setLoanApplication(application))
        onSuccessUpdateApplicaton.reset()
        dispatch(fetchStop())
      } else {
      }

      if (onSuccessUpdateApplicaton.error) {
        const { networkError } = onSuccessUpdateApplicaton.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessUpdateApplicaton.loading,
    onSuccessUpdateApplicaton.data,
    onSuccessUpdateApplicaton.error,
    onSuccessUpdateApplicaton.called,
  ])

  const additionalProps: any = {
    updateApplication: updateApplication,
    getApplication: getApplication,
  }
  /* const progressPercentageCalculate = (currentStep: number) => {
    let currentStepIndex = currentStep - 1
    if(currentStep >= 2) return currentStepIndex / 5 * 100
    if (currentStep >= 1 &&  currentStep <  2) return parseFloat((20 * (((currentStep * 10 % 10) - 1) / 3)).toFixed(2))
    return 0
  } */

  const progressPercentageCalculate = () => {
    let progress = 0
    if (loanStep > 0 || loanSubStep > 0) {
      if (loanStep === 1) {
        progress = Math.floor(5 * loanSubStep)
      } else if (loanStep > 1) {
        progress = Math.floor(20 * loanStep)
      }
    }

    return progress
  }

  useEffect(() => {
    ReactPixel.track('Applicaiton Registration Start')
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 142) {
        document.body.classList.add('fixed')
      } else if (window.scrollY < 105) {
        document.body.classList.remove('fixed')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <div className='container loanApplication'>
        <div
          id='head'
          className={loanSubStep !== 0 || loanStep > 1 ? 'cardNew stepperNewWrap' : 'cardNew'}
        >
          {loanSubStep === 0 && loanStep === 1 && (
            <div className='resumeApplication'>
              <CheckCircleIcon fontSize={32} color='var(--primary-color)' />
              <div>
                <h3 className='section-heading'>Resume Application</h3>
                <p>
                  Welcome back, you are hardly 2 steps away, please continue the journey and
                  complete the application.
                </p>
              </div>
            </div>
          )}
          {(loanSubStep !== 0 || loanStep > 1) && (
            <ul className='stepperNew'>
              <li className={loanStep === 1 ? 'current' : loanStep > 1 ? 'completed' : ''}>
                <div className='stepBox'>
                  <div className='iconWrap'>
                    {loanStep > 1 ? (
                      <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                    ) : (
                      <Step1Icon
                        color={
                          loanStep === 1 ? 'var(--text-color-primary)' : 'var(--border-color-grey1)'
                        }
                      />
                    )}
                  </div>
                  <div className='right'>
                    <span>STEP 1</span>
                    <p className='truncate-text' data-tooltip='Loan Eligibility'>
                      Loan Eligibility
                    </p>
                  </div>
                </div>
              </li>
              <li className={loanStep === 2 ? 'current' : loanStep > 2 ? 'completed' : ''}>
                <div className='stepBox'>
                  <div className='iconWrap'>
                    {loanStep > 2 ? (
                      <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                    ) : (
                      <Step2Icon
                        color={
                          loanStep === 2 ? 'var(--text-color-primary)' : 'var(--border-color-grey1)'
                        }
                      />
                    )}
                  </div>
                  <div className='right'>
                    <span>STEP 2</span>
                    <p className='truncate-text' data-tooltip='Income Documents'>
                      Income Documents
                    </p>
                  </div>
                </div>
              </li>
              <li className={loanStep === 3 ? 'current' : loanStep > 3 ? 'completed' : ''}>
                <div className='stepBox'>
                  <div className='iconWrap'>
                    {loanStep > 3 ? (
                      <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                    ) : (
                      <Step3Icon
                        color={
                          loanStep === 3 ? 'var(--text-color-primary)' : 'var(--border-color-grey1)'
                        }
                      />
                    )}
                  </div>
                  <div className='right'>
                    <span>STEP 3</span>
                    <p className='truncate-text' data-tooltip='Company Profile'>
                      Company Profile
                    </p>
                  </div>
                </div>
              </li>
              <li className={loanStep === 4 ? 'current' : loanStep > 4 ? 'completed' : ''}>
                <div className='stepBox'>
                  <div className='iconWrap'>
                    {loanStep > 4 ? (
                      <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                    ) : (
                      <Step4Icon
                        color={
                          loanStep === 4 ? 'var(--text-color-primary)' : 'var(--border-color-grey1)'
                        }
                      />
                    )}
                  </div>
                  <div className='right'>
                    <span>STEP 4</span>
                    <p className='truncate-text' data-tooltip='Shareholders'>
                      Shareholders
                    </p>
                  </div>
                </div>
              </li>
              <li className={loanStep === 5 ? 'current' : loanStep > 5 ? 'completed' : ''}>
                <div className='stepBox'>
                  <div className='iconWrap'>
                    {loanStep > 5 ? (
                      <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                    ) : (
                      <Step5Icon
                        color={
                          loanStep === 5 ? 'var(--text-color-primary)' : 'var(--border-color-grey1)'
                        }
                      />
                    )}
                  </div>
                  <div className='right'>
                    <span>STEP 5</span>
                    <p className='truncate-text' data-tooltip='Review Application'>
                      Review Application
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
        <div className={loanStep === 1 ? 'current' : ''} data-kt-stepper-element='content'>
          {loanStep === 1 && <Step1 {...additionalProps} />}
        </div>

        <div className={loanStep === 2 ? 'current' : ''} data-kt-stepper-element='content'>
          {loanStep === 2 && <Step2 {...additionalProps} />}
        </div>

        <div className={loanStep === 3 ? 'current' : ''} data-kt-stepper-element='content'>
          {loanStep === 3 && <Step3 {...additionalProps} />}
        </div>

        <div className={loanStep === 4 ? 'current' : ''} data-kt-stepper-element='content'>
          {loanStep === 4 && <Step3Sub1 {...additionalProps} />}
        </div>

        <div className={loanStep === 5 ? 'current' : ''} data-kt-stepper-element='content'>
          {loanStep === 5 && <Step4 {...additionalProps} />}
        </div>
      </div>
      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </>
  )
}

export default LoanApplication
