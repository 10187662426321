import { SVGProps } from 'react'

export function UploadDataIcon({
  color = 'var(--primary-color)',
  fontSize = '32',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 32 32'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <rect
        width='32'
        height='32'
        rx='16'
        fill='url(#paint0_linear_28006_17513)'
        fill-opacity='0.1'
      />
      <path
        d='M18.5472 12.6258C19.5883 12.7372 20.6356 12.7799 21.6823 12.7537L21.6798 12.7496C20.4437 10.8835 18.8474 9.28287 16.9847 8.04167C16.9577 9.09357 17.0004 10.1461 17.1125 11.1924C17.1925 11.8836 17.8557 12.5468 18.5472 12.6258ZM20.0616 17.6309C19.4317 17.6309 18.8159 17.8177 18.2921 18.1677C17.7683 18.5177 17.3601 19.0152 17.119 19.5971C16.8779 20.1791 16.8149 20.8196 16.9378 21.4374C17.0607 22.0552 17.364 22.6228 17.8095 23.0682C18.2549 23.5136 18.8224 23.817 19.4403 23.9399C20.0581 24.0628 20.6985 23.9997 21.2805 23.7586C21.8625 23.5176 22.36 23.1093 22.7099 22.5856C23.0599 22.0618 23.2467 21.446 23.2467 20.816C23.2467 19.9713 22.9111 19.1612 22.3138 18.5638C21.7165 17.9665 20.9064 17.6309 20.0616 17.6309ZM21.5867 20.756C21.546 20.7967 21.4978 20.8289 21.4447 20.8509C21.3916 20.8729 21.3348 20.8842 21.2773 20.8842C21.2198 20.8842 21.1629 20.8729 21.1099 20.8509C21.0568 20.8289 21.0085 20.7967 20.9679 20.756L20.4991 20.2873V22.3976C20.4991 22.5137 20.4531 22.6249 20.371 22.707C20.289 22.789 20.1777 22.8351 20.0616 22.8351C19.9456 22.8351 19.8343 22.789 19.7523 22.707C19.6702 22.6249 19.6241 22.5137 19.6241 22.3976V20.2888L19.1554 20.7576C19.0733 20.8396 18.962 20.8857 18.846 20.8857C18.7299 20.8857 18.6187 20.8396 18.5366 20.7576C18.4546 20.6755 18.4085 20.5642 18.4085 20.4482C18.4085 20.3322 18.4546 20.2209 18.5366 20.1388L19.7523 18.9235C19.7929 18.8828 19.8411 18.8506 19.8942 18.8286C19.9473 18.8066 20.0042 18.7953 20.0616 18.7953C20.1191 18.7953 20.176 18.8066 20.2291 18.8286C20.2822 18.8506 20.3304 18.8828 20.371 18.9235L21.5867 20.1388C21.6273 20.1794 21.6595 20.2277 21.6815 20.2808C21.7035 20.3338 21.7148 20.3907 21.7148 20.4482C21.7148 20.5057 21.7035 20.5626 21.6815 20.6156C21.6595 20.6687 21.6273 20.717 21.5867 20.7576V20.756Z'
        fill='url(#paint1_linear_28006_17513)'
      />
      <path
        d='M21.7117 13.5889C21.5035 13.5942 21.2957 13.5977 21.0888 13.5977C20.2102 13.5974 19.3322 13.5505 18.4585 13.4573H18.451C17.93 13.397 17.4197 13.1386 17.0097 12.7298C16.5997 12.3211 16.3428 11.8095 16.2822 11.2888C16.2823 11.2863 16.2823 11.2838 16.2822 11.2813C16.1661 10.1953 16.1218 9.10282 16.1497 8.01096C14.5076 7.97138 12.8647 8.03903 11.2314 8.21346C10.1405 8.34003 9.09451 9.3863 8.96763 10.4773C8.68169 13.1539 8.68169 18.124 8.96763 20.8006C9.0942 21.8916 10.1405 22.9379 11.2314 23.0644C13.0836 23.2627 14.9479 23.3234 16.8091 23.246C16.4148 22.7174 16.1548 22.1009 16.0514 21.4496C15.948 20.7983 16.0042 20.1316 16.2153 19.5068H11.8552C11.7392 19.5068 11.6279 19.4608 11.5458 19.3787C11.4638 19.2967 11.4177 19.1854 11.4177 19.0693C11.4177 18.9533 11.4638 18.842 11.5458 18.76C11.6279 18.6779 11.7392 18.6318 11.8552 18.6318H16.6366C16.9841 18.0898 17.4541 17.6371 18.0088 17.3102H11.8552C11.7392 17.3102 11.6279 17.2641 11.5458 17.1821C11.4638 17.1 11.4177 16.9888 11.4177 16.8727C11.4177 16.7567 11.4638 16.6454 11.5458 16.5634C11.6279 16.4813 11.7392 16.4352 11.8552 16.4352H18.6366C18.7526 16.4352 18.8639 16.4813 18.946 16.5634C19.028 16.6454 19.0741 16.7567 19.0741 16.8727V16.8752C19.9589 16.6561 20.8915 16.7386 21.7242 17.1096C21.7482 15.9474 21.7439 14.7283 21.7117 13.5889ZM16.4503 15.1139H11.8564C11.7404 15.1139 11.6291 15.0678 11.5471 14.9858C11.465 14.9037 11.4189 14.7925 11.4189 14.6764C11.4189 14.5604 11.465 14.4491 11.5471 14.3671C11.6291 14.285 11.7404 14.2389 11.8564 14.2389H16.4503C16.5663 14.2389 16.6776 14.285 16.7597 14.3671C16.8417 14.4491 16.8878 14.5604 16.8878 14.6764C16.8878 14.7925 16.8417 14.9037 16.7597 14.9858C16.6776 15.0678 16.5663 15.1139 16.4503 15.1139Z'
        fill='url(#paint2_linear_28006_17513)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_28006_17513'
          x1='-7.52381'
          y1='16'
          x2='31.9295'
          y2='17.6684'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_28006_17513'
          x1='5.34547'
          y1='16.0005'
          x2='23.2205'
          y2='16.6852'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_28006_17513'
          x1='5.34547'
          y1='16.0005'
          x2='23.2205'
          y2='16.6852'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
      </defs>
    </svg>
  )
}
