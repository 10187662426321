import {
  SET_CO_APPLICANT,
  SET_SUB_STEP,
  SET_OPEN_VERIFICATION,
  SET_APPLICATION_COMPLETE,
  SET_COMPANY_VERIFICATION_METHOD,
  SET_PERSON_DATA,
  SET_PARTNER_DETAILS,
  SET_FUND_DETAILS,
} from '../types'

export const setSubStep = (step: number) => ({
  type: SET_SUB_STEP,
  step,
})

export const setCoApplicant = (co_applicant: any) => ({
  type: SET_CO_APPLICANT,
  co_applicant,
})

export const setPartnerDetails = (payload: any) => ({
  type: SET_PARTNER_DETAILS,
  payload,
})

export const setOpenVerification = (open: boolean) => ({
  type: SET_OPEN_VERIFICATION,
  open,
})

export const setApplicationComplete = (complete: boolean) => ({
  type: SET_APPLICATION_COMPLETE,
  complete,
})

export const setCompanyVerificationMethod = (method: number) => ({
  type: SET_COMPANY_VERIFICATION_METHOD,
  method,
})

export const setPersonData = (payload: any) => ({
  type: SET_PERSON_DATA,
  payload,
})

export const setFundDetails = (payload: any) => ({
  type: SET_FUND_DETAILS,
  payload,
})
