import { SVGProps } from 'react'
export function MobileIcon({
  color = 'var(--text-color-2)',
  fontSize = '22',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 22 22'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <path
        d='M18.3472 14.2291V16.6377C18.3481 16.8613 18.3022 17.0827 18.2124 17.2875C18.1227 17.4924 17.991 17.6763 17.8259 17.8275C17.6608 17.9786 17.4659 18.0937 17.2537 18.1654C17.0414 18.237 16.8165 18.2636 16.5934 18.2435C14.1179 17.975 11.74 17.1308 9.65078 15.7786C7.70703 14.5459 6.05907 12.9012 4.82393 10.9613C3.46434 8.86679 2.61825 6.48206 2.35419 4.00036C2.33408 3.77834 2.36052 3.55457 2.43182 3.3433C2.50312 3.13203 2.61771 2.9379 2.7683 2.77325C2.9189 2.60861 3.10219 2.47706 3.30652 2.38699C3.51084 2.29691 3.73172 2.25029 3.95509 2.25008H6.36852C6.75894 2.24624 7.13743 2.38422 7.43345 2.6383C7.72948 2.89237 7.92283 3.24521 7.97747 3.63103C8.07934 4.40185 8.26825 5.1587 8.5406 5.88713C8.64884 6.1745 8.67227 6.48682 8.6081 6.78707C8.54394 7.08732 8.39488 7.36292 8.17859 7.58121L7.15691 8.60087C8.30212 10.6109 9.96972 12.2752 11.9838 13.4182L13.0054 12.3985C13.2242 12.1826 13.5003 12.0339 13.8012 11.9698C14.102 11.9058 14.4149 11.9292 14.7029 12.0372C15.4328 12.309 16.1911 12.4976 16.9635 12.5992C17.3543 12.6542 17.7111 12.8507 17.9663 13.1512C18.2214 13.4517 18.357 13.8353 18.3472 14.2291Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
