import { SVGProps } from 'react'

export function CompanyProfile({
  color = 'var(--primary-color)',
  fontSize = '60',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 60 61'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <rect y='0.304688' width='60' height='60' rx='30' fill='var(--secondary-light-color)' />
      <path
        d='M24.6665 34.5812L26.5518 32.6945L28.8812 34.9732L33.5692 30.2852L35.4545 32.1705L28.8545 38.7705L24.6665 34.5812Z'
        fill='url(#paint0_linear_26012_82659)'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M22 14.3047C20.9391 14.3047 19.9217 14.7261 19.1716 15.4763C18.4214 16.2264 18 17.2438 18 18.3047V42.3047C18 43.3656 18.4214 44.383 19.1716 45.1331C19.9217 45.8833 20.9391 46.3047 22 46.3047H38C39.0609 46.3047 40.0783 45.8833 40.8284 45.1331C41.5786 44.383 42 43.3656 42 42.3047V24.3807C42 23.9034 41.8293 23.4418 41.5187 23.0794L34.5987 15.0034C34.4109 14.7842 34.178 14.6083 33.9158 14.4877C33.6537 14.3671 33.3686 14.3047 33.08 14.3047H22ZM22 19.638H34V20.9714H22V19.638ZM38 34.3047C38 36.4264 37.1571 38.4613 35.6569 39.9615C34.1566 41.4618 32.1217 42.3047 30 42.3047C27.8783 42.3047 25.8434 41.4618 24.3431 39.9615C22.8429 38.4613 22 36.4264 22 34.3047C22 32.183 22.8429 30.1481 24.3431 28.6478C25.8434 27.1475 27.8783 26.3047 30 26.3047C32.1217 26.3047 34.1566 27.1475 35.6569 28.6478C37.1571 30.1481 38 32.183 38 34.3047ZM22 22.3047H31.3333V23.638H22V22.3047Z'
        fill='url(#paint1_linear_26012_82659)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_26012_82659'
          x1='22.13'
          y1='34.5278'
          x2='35.4161'
          y2='35.2421'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_26012_82659'
          x1='12.3571'
          y1='30.3047'
          x2='41.9702'
          y2='31.2439'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
      </defs>
    </svg>
  )
}
