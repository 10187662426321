import React, { FunctionComponent, useMemo } from 'react'
import { Stack } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { UploadIcon } from '../../../svg/UploadIcon'

interface UploadDropZoneProps {
  onDrop: (acceptedFiles: Array<File>) => void
  disabled?: boolean
  singleFiltSupprt?: string
  dropTitleText?: string
}
// * This component is using react-dropzone which helps in drag and drop files it sets a state with list of added files object
export const UploadDropZone: FunctionComponent<UploadDropZoneProps> = ({
  onDrop,
  singleFiltSupprt = '',
  disabled = false,
  dropTitleText = 'Bank Statements',
}) => {
  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: { 'application/pdf': [] },
    })

  const baseStyle = {
    px: 3,
    py: 5,
    borderRadius: 1,
    border: '1px dashed #BBBFC4',
    backgroundColor: '#F9FAFA',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    pointerEvents: 'auto',
  }

  // border:'1px dashed #BBBFC4', padding: '40px 15px', background:"rgba(26,3,85,0.02)", minHeight:'100px', marginBottom:'15px'

  return (
    <div
      {...getRootProps(baseStyle)}
      style={{
        border: '1px dashed #BBBFC4',
        padding: '63px 40px',
        background: 'rgba(26,3,85,0.02)',
        minHeight: '150px',
        fontSize: '14px',
        marginBottom: '15px',
        borderRadius: '10px',
      }}
    >
      <input {...getInputProps()} />

      <Stack
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        gap={2} // Increased gap for better spacing
      >
        <UploadIcon color='var(--primary-color)' />
        <div className='file-uploader' style={{ cursor: 'pointer' }}>
          <span>Drag & Drop {dropTitleText}</span>
        </div>
        {/* Add more text here */}
        {singleFiltSupprt !== '' ? (
          <div className='file-uploader-sub'>Supported format: {singleFiltSupprt} Only</div>
        ) : (
          <div className='file-uploader-sub'>
            Supported formats: JPEG, PNG, PDF, DOCX, XLSX, XLS
          </div>
        )}
      </Stack>
    </div>
  )
}
