import { SVGProps } from 'react'

export function TileIcon({
  color = 'var(--primary-color)',
  fontSize = '24',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 24 23'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <rect y='0.0195312' width='10.2857' height='10.2857' rx='2' fill='#EAEAEA' />
      <rect x='12.2856' y='0.0195312' width='10.2857' height='10.2857' rx='2' fill='#F2F2F2' />
      <rect y='12.3047' width='10.2857' height='10.2857' rx='2' fill='#F2F2F2' />
      <rect x='12.2856' y='12.3047' width='10.2857' height='10.2857' rx='2' fill='#F2F2F2' />
    </svg>
  )
}
