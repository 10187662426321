import { SVGProps } from 'react'

export function UploadIcon({
  color = 'var(--primary-color)',
  fontSize = '40',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={fontSize}
        height={fontSize}
        style={style}
        onClick={onClick}
        viewBox='0 0 48 40'
        fill='none'
      >
        <path
          d='M25.1277 9.8022C24.8677 9.72466 24.7196 9.45135 24.7972 9.19135C25.7003 6.15643 28.5131 3.92808 31.86 3.92808C32.1312 3.92808 32.3512 4.14807 32.3512 4.41929C32.3512 4.6905 32.1312 4.9105 31.86 4.9105C29.0549 4.9105 26.5378 6.78585 25.7386 9.47129C25.6617 9.73092 25.3887 9.88009 25.1277 9.8022Z'
          fill={color}
        />
        <path
          d='M38.7365 28.4884H35.7893C35.5181 28.4884 35.2981 28.2684 35.2981 27.9972C35.2981 27.726 35.5181 27.506 35.7893 27.506H38.7365C42.799 27.506 46.1045 24.2005 46.1045 20.138C46.1045 16.0754 42.799 12.7699 38.7365 12.7699H38.6656C38.5231 12.7699 38.3877 12.7082 38.2944 12.6005C38.201 12.4928 38.1589 12.35 38.1793 12.2089C38.2231 11.903 38.2453 11.5956 38.2453 11.2963C38.2453 7.77549 35.3809 4.9105 31.86 4.9105C30.4902 4.9105 29.1836 5.33873 28.0819 6.14885C27.8398 6.32674 27.4959 6.2478 27.3559 5.98149C24.2358 0.0400753 16.0862 -0.757796 11.8611 4.41072C10.0812 6.58813 9.38187 9.42061 9.94226 12.1812C10.004 12.4861 9.77069 12.7703 9.46088 12.7703H9.26405C5.20149 12.7703 1.89603 16.0758 1.89603 20.1383C1.89603 24.2009 5.20149 27.5064 9.26405 27.5064H12.2112C12.4824 27.5064 12.7024 27.7263 12.7024 27.9976C12.7024 28.2688 12.4824 28.4888 12.2112 28.4888H9.26405C4.6597 28.4888 0.913574 24.7427 0.913574 20.1383C0.913574 15.6631 4.4523 11.9987 8.87845 11.7966C8.46268 8.93254 9.25773 6.04357 11.1004 3.789C15.624 -1.74508 24.2932 -1.12478 27.9578 5.04612C29.1269 4.31317 30.4653 3.92808 31.86 3.92808C36.1257 3.92808 39.5054 7.55937 39.2096 11.8012C43.595 12.0472 47.0867 15.6922 47.0867 20.1379C47.0867 24.7427 43.3408 28.4884 38.7365 28.4884Z'
          fill={color}
        />
        <path
          d='M23.9344 40C17.1631 40 11.6542 34.4912 11.6542 27.7198C11.6542 20.9485 17.163 15.4396 23.9344 15.4396C30.7057 15.4396 36.2146 20.9485 36.2146 27.7198C36.2146 34.4911 30.7057 40 23.9344 40ZM23.9344 16.4223C17.7049 16.4223 12.6368 21.4904 12.6368 27.7198C12.6368 33.9493 17.705 39.0174 23.9344 39.0174C30.1637 39.0174 35.2319 33.9492 35.2319 27.7198C35.2319 21.4903 30.1637 16.4223 23.9344 16.4223Z'
          fill={color}
        />
        <path
          d='M24.1588 33.026C23.9603 33.026 23.7992 32.8649 23.7992 32.6664V23.3163C23.7992 23.1177 23.9603 22.9567 24.1588 22.9567C24.3574 22.9567 24.5184 23.1177 24.5184 23.3163V32.6664C24.5184 32.8652 24.3574 33.026 24.1588 33.026Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M24.1588 33.326C23.7946 33.326 23.4992 33.0306 23.4992 32.6664V23.3163C23.4992 22.9521 23.7946 22.6567 24.1588 22.6567C24.5231 22.6567 24.8184 22.952 24.8184 23.3163V32.6664C24.8184 33.031 24.5229 33.326 24.1588 33.326ZM23.7992 32.6664C23.7992 32.8649 23.9603 33.026 24.1588 33.026C24.3574 33.026 24.5184 32.8652 24.5184 32.6664V23.3163C24.5184 23.1177 24.3574 22.9567 24.1588 22.9567C23.9603 22.9567 23.7992 23.1177 23.7992 23.3163V32.6664Z'
          fill={color}
        />
        <path
          d='M27.0356 26.5529C26.9437 26.5529 26.8515 26.5177 26.7813 26.4475L24.1587 23.8249L21.5361 26.4475C21.3959 26.5881 21.168 26.5881 21.0275 26.4475C20.887 26.307 20.887 26.0795 21.0275 25.939L23.9044 23.0621C24.0447 22.9215 24.2725 22.9215 24.413 23.0621L27.2899 25.939C27.4304 26.0795 27.4304 26.307 27.2899 26.4475C27.2198 26.5179 27.1276 26.5529 27.0356 26.5529Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M27.0356 26.5529C26.9437 26.5529 26.8515 26.5177 26.7813 26.4475L24.1587 23.8249L21.5361 26.4475C21.3959 26.5881 21.168 26.5881 21.0275 26.4475C20.887 26.307 20.887 26.0795 21.0275 25.939L23.9044 23.0621C24.0447 22.9215 24.2725 22.9215 24.413 23.0621L27.2899 25.939C27.4304 26.0795 27.4304 26.307 27.2899 26.4475C27.2198 26.5179 27.1276 26.5529 27.0356 26.5529C27.0356 26.5529 27.0356 26.5529 27.0356 26.5529ZM27.0356 26.8529C27.2034 26.8529 27.3733 26.7889 27.5024 26.6593C27.7601 26.4016 27.7597 25.9845 27.502 25.7268L24.6251 22.8499C24.3676 22.5924 23.9499 22.5921 23.6923 22.8499C23.6923 22.8499 23.6922 22.85 23.6923 22.8499L20.8154 25.7268C20.5577 25.9845 20.5577 26.402 20.8154 26.6597C21.0729 26.9172 21.4907 26.9175 21.7482 26.6597L24.1587 24.2492L26.5692 26.6597C26.5692 26.6597 26.5692 26.6597 26.5692 26.6597C26.6978 26.7883 26.8674 26.8529 27.0356 26.8529Z'
          fill={color}
        />
      </svg>
    </>
  )
}
