import { SVGProps } from 'react'
export function CheckCircleIcon({
  color = 'var(--text-color-2)',
  fontSize = '14',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 14 14'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <path
        d='M7.25 13.75C10.9779 13.75 14 10.7279 14 7C14 3.27208 10.9779 0.25 7.25 0.25C3.52208 0.25 0.5 3.27208 0.5 7C0.5 10.7279 3.52208 13.75 7.25 13.75Z'
        fill={color}
      />
      <path
        d='M5 7L6.5 8.5L9.5 5.5'
        stroke='white'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
