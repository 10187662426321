import { SVGProps } from 'react'
export function Step1Icon({
  color = 'var(--text-color-2)',
  fontSize = '24',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={fontSize}
        height={fontSize}
        viewBox='0 0 24 24'
        fill='none'
        style={style}
        onClick={onClick}
      >
        <path
          d='M10.1483 0C8.71762 0 7.34549 0.56808 6.33382 1.57927C5.32214 2.59046 4.75379 3.96193 4.75379 5.39196C4.75379 6.822 5.32214 8.19347 6.33382 9.20466C7.34549 10.2158 8.71762 10.7839 10.1483 10.7839C11.5791 10.7839 12.9512 10.2158 13.9629 9.20466C14.9745 8.19347 15.5429 6.822 15.5429 5.39196C15.5429 3.96193 14.9745 2.59046 13.9629 1.57927C12.9512 0.56808 11.5791 0 10.1483 0ZM10.6849 13.4172C10.9885 12.9769 10.6969 12.3051 10.1627 12.3051H8.72604C6.59706 12.3051 4.55528 13.1504 3.04987 14.6551C1.54446 16.1598 0.69873 18.2006 0.69873 20.3286C0.69873 21.1972 1.04395 22.0302 1.65845 22.6444C2.27295 23.2587 3.10639 23.6037 3.97543 23.6037H10.2408C10.7833 23.6037 11.0737 22.9151 10.7593 22.4748C9.80419 21.1376 9.29211 19.535 9.29496 17.892C9.29496 16.2317 9.80867 14.6889 10.6849 13.4172Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M23.3018 17.8984C23.3018 19.5166 22.6588 21.0686 21.5142 22.2129C20.3696 23.3572 18.8172 24 17.1985 24C15.5798 24 14.0275 23.3572 12.8829 22.2129C11.7383 21.0686 11.0953 19.5166 11.0953 17.8984C11.0953 16.2801 11.7383 14.7281 12.8829 13.5839C14.0275 12.4396 15.5798 11.7967 17.1985 11.7967C18.8172 11.7967 20.3696 12.4396 21.5142 13.5839C22.6588 14.7281 23.3018 16.2801 23.3018 17.8984ZM20.6925 15.5789C20.7815 15.6568 20.8543 15.7515 20.9067 15.8575C20.9591 15.9635 20.9901 16.0788 20.9979 16.1969C21.0057 16.3149 20.9902 16.4333 20.9522 16.5453C20.9142 16.6573 20.8545 16.7607 20.7765 16.8496L17.6918 20.369C17.5559 20.5239 17.3896 20.6491 17.2032 20.7368C17.0168 20.8246 16.8143 20.873 16.6083 20.8791C16.4024 20.8852 16.1973 20.8488 16.0061 20.7722C15.8148 20.6956 15.6414 20.5804 15.4966 20.4338L13.7646 18.6819C13.6815 18.5978 13.6157 18.4981 13.5711 18.3885C13.5265 18.279 13.5039 18.1617 13.5047 18.0434C13.5054 17.9252 13.5294 17.8082 13.5753 17.6992C13.6213 17.5902 13.6882 17.4913 13.7724 17.4082C13.8566 17.3251 13.9563 17.2593 14.0659 17.2148C14.1755 17.1702 14.2928 17.1476 14.4111 17.1483C14.5294 17.149 14.6464 17.173 14.7554 17.219C14.8644 17.2649 14.9633 17.3319 15.0465 17.416L16.0979 18.4815C16.1559 18.5402 16.2253 18.5863 16.3019 18.617C16.3784 18.6476 16.4605 18.6621 16.5429 18.6597C16.6254 18.6572 16.7064 18.6378 16.781 18.6026C16.8556 18.5674 16.9222 18.5172 16.9765 18.4551L19.4214 15.6641C19.4993 15.5751 19.594 15.5023 19.7 15.4499C19.8061 15.3975 19.9215 15.3665 20.0395 15.3587C20.1576 15.3509 20.276 15.3664 20.388 15.4044C20.5001 15.4424 20.6035 15.5009 20.6925 15.5789Z'
          fill={color}
        />
      </svg>
    </>
  )
}
