import { SVGProps } from 'react'

export function Xicon({ fontSize = '20', style = {}, onClick }: SVGProps<SVGElement>) {
  return (
    <svg
      style={style}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='9'
      height='10'
      viewBox='0 0 9 10'
      fill='none'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0.71967 0.96967C1.01256 0.676777 1.48744 0.676777 1.78033 0.96967L4.75 3.93934L7.71967 0.96967C8.01256 0.676777 8.48744 0.676777 8.78033 0.96967C9.07322 1.26256 9.07322 1.73744 8.78033 2.03033L5.81066 5L8.78033 7.96967C9.07322 8.26256 9.07322 8.73744 8.78033 9.03033C8.48744 9.32322 8.01256 9.32322 7.71967 9.03033L4.75 6.06066L1.78033 9.03033C1.48744 9.32322 1.01256 9.32322 0.71967 9.03033C0.426777 8.73744 0.426777 8.26256 0.71967 7.96967L3.68934 5L0.71967 2.03033C0.426777 1.73744 0.426777 1.26256 0.71967 0.96967Z'
        fill='var(--error-red)'
      />
    </svg>
  )
}
