import React, { FC, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import CoApplicant from './CoApplicant'
// import LoanType from "./LoanType";
import CoApplicantComplete from './CoApplicantComplete'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { parse } from 'query-string'
import { getIntegrationURLFromENV } from '../../../utils/getIntegrationURLFromENV'
import axios from 'axios'
import { setFundDetails } from './redux/actions'

const CoApplicantWrapper: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const searchQuery: any = parse(history.location.search)
  const partnerDetails = useSelector((state: any) => state.coApplicant.partner)
  console.log('partnerDetails', partnerDetails)
  const fundDetails = useSelector((state: any) => state.coApplicant.fund)
  console.log('fundDetails', fundDetails)
  useEffect(() => {
    if (partnerDetails != null && partnerDetails?.funds?.length > 0) {
      const selectedFund = partnerDetails?.funds[0]
      getFundDetails(selectedFund)
    }
  }, [partnerDetails?.funds?.length])

  const getFundDetails = async (fundId: string) => {
    const env = localStorage.getItem('env')
    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)
    const fundData = await axios.get(`${API_URL}ext/api/funds/${fundId || ''}`)

    if (fundData.data && fundData.status === 200) {
      dispatch(setFundDetails(fundData.data))
    }
  }

  const getThemeById = async () => {
    const env = localStorage.getItem('env')
    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)
    try {
      const theme: any = await axios.get(`${API_URL}ext/api/theme/${fundDetails.theme}`)
      if (theme.data) {
        const fontFamily = theme.data.themeDetails.themeFont
          .map((font: any) => font as string)
          .join(',')
        document.body.setAttribute('theme', theme.data.themeName)
        document.body.style.setProperty('--primary-color', theme.data.themeDetails.primaryColor)
        document.body.style.setProperty(
          '--primary-light-color',
          theme.data.themeDetails.primaryLightColor
        )
        document.body.style.setProperty('--secondary-color', theme.data.themeDetails.secondaryColor)
        document.body.style.setProperty(
          '--secondary-light-color',
          theme.data.themeDetails.secondaryLightColor
        )
        document.body.style.setProperty('--font-family-primary', fontFamily)
        document.body.style.setProperty(
          '--main-background',
          theme.data.themeDetails.backgroundColor
        )
      } else {
        document.body.removeAttribute('theme')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  useEffect(() => {
    if (fundDetails?.theme) {
      getThemeById()
    }
  }, [fundDetails?.theme])

  useEffect(() => {
    if (fundDetails?.fundFavIcon) {
      const shortcutIcon = document.querySelector("link[rel='shortcut icon']")
      if (shortcutIcon) {
        shortcutIcon.setAttribute('href', `${fundDetails.fundFavIcon}`)
      }
      const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']")
      if (appleTouchIcon) {
        appleTouchIcon.setAttribute('href', `${fundDetails.fundFavIcon}`)
      }
    }
  }, [fundDetails?.fundFavIcon])

  return (
    <>
      <div className='headerAlexi'>
        <div className='container'>
          <div className='d-flex justify-content-between'>
            <img alt='Logo' src={fundDetails?.fundLogo || '/media/logos/logo_admin.jpg'} />
            <div className='symbol symbol-35px symbol-circle'>
              <span className='symbol-label bg-secondary text-inverse-secondary fw-bolder'>CK</span>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route path='/co-applicant/completed' exact>
          {' '}
          <CoApplicantComplete />{' '}
        </Route>
        <Route path='/co-applicant' exact>
          {' '}
          <CoApplicant />{' '}
        </Route>
        <Route path='/co-applicant/:id' exact>
          <CoApplicant />
        </Route>
      </Switch>
    </>
  )
}

export default CoApplicantWrapper
