import React, { FunctionComponent, useMemo } from 'react'
import { Button, Stack } from 'react-bootstrap'
import { UploadIcon } from '../../../svg/UploadIcon'

interface ReUploadComponentProps {
  setReuploadWarningModal: (flag: boolean) => void
}

export const ReUploadComponent: FunctionComponent<ReUploadComponentProps> = ({
  setReuploadWarningModal,
}) => {
  return (
    <div
      style={{
        border: '1px dashed #BBBFC4',
        padding: '63px 40px',
        background: 'rgba(26,3,85,0.02)',
        minHeight: '150px',
        fontSize: '14px',
        marginBottom: '15px',
        borderRadius: '10px',
      }}
    >
      <Stack
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        gap={2}
      >
        <UploadIcon color='currentColor' />
        <div>
          <div
            className='file-uploader'
            style={{ cursor: 'pointer' }}
            onClick={() => setReuploadWarningModal(true)}
          >
            <span>Re Upload Bank statements</span>
          </div>
        </div>
        <div className='file-uploader-sub'>Supported format: PDF Only</div>
      </Stack>
    </div>
  )
}
