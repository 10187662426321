import {
  SET_KYC_APPLICATION,
  SET_KYC_APPLICATION_STATUS,
  SET_KYC_STEP,
  SET_KYC_SUB_STEP,
  SET_COMPANY_VERIFICATION_METHOD,
  SET_ENTITY_DATA,
  SET_PERSON_DATA,
  SET_SHAREHOLDERS,
  SET_KYC_ELIGIBILITY,
  SET_GUARANTOR,
  SET_DECISION_MAKER,
  SET_SINGPASS_INFO,
  AUTHORIZE_ACRA_SUCCEEDED,
  FETCH_ENTITY_PERSON_SUCCEEDED,
  RESET_KYC,
  SET_DIRECTOR,
  APPLICATION_DETAILS_DELETE,
  APPLICATION_DETAILS_SAVE,
  SET_FUND_DETAILS,
  SET_PARTNER_DETAILS,
} from '../types'

const INITIAL_STATE = {
  partner: null,
  loanApplication: null,
  loanApplicationStatus: 1,
  loanStep: 0,
  loanSubStep: 1,
  loanEligibility: null,
  shareholders: null,
  guarantor: null,
  decisionMaker: null,
  directors: null,
  companyVerificationMethod: 1,
  acraToken: '',
  entityPerson: {
    entity: null,
    person: null,
  },
  singPass: null,
  applicationDetails: null,
  fund: null,
}

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_KYC_APPLICATION: {
      const loanApplication = action.payload
      return {
        ...state,
        loanApplication,
      }
    }
    case SET_KYC_APPLICATION_STATUS: {
      return {
        ...state,
        loanApplicationStatus: action.status,
      }
    }
    case SET_KYC_STEP: {
      return {
        ...state,
        loanStep: action.step,
      }
    }
    case SET_KYC_SUB_STEP: {
      return {
        ...state,
        loanSubStep: action.step,
      }
    }
    case SET_COMPANY_VERIFICATION_METHOD: {
      return {
        ...state,
        companyVerificationMethod: action.method,
      }
    }
    case SET_ENTITY_DATA: {
      return {
        ...state,
        entityPerson: action.payload,
      }
    }
    case SET_PERSON_DATA: {
      return {
        ...state,
        personData: action.payload,
      }
    }
    case SET_KYC_ELIGIBILITY: {
      return {
        ...state,
        loanEligibility: action.payload,
      }
    }
    case SET_SHAREHOLDERS: {
      return {
        ...state,
        shareholders: action.payload,
      }
    }
    case SET_GUARANTOR: {
      return {
        ...state,
        guarantor: action.payload,
      }
    }
    case SET_DECISION_MAKER: {
      return {
        ...state,
        decisionMaker: action.payload,
      }
    }
    case SET_DIRECTOR: {
      return {
        ...state,
        directors: action.payload,
      }
    }
    case SET_SINGPASS_INFO: {
      return {
        ...state,
        singPass: action.payload,
      }
    }
    case AUTHORIZE_ACRA_SUCCEEDED: {
      const { access_token } = action.payload

      localStorage.setItem('acra_access_token', access_token)

      return {
        ...state,
        acraToken: access_token,
      }
    }
    case FETCH_ENTITY_PERSON_SUCCEEDED: {
      const { payload } = action

      return {
        ...state,
        entityPerson: payload,
      }
    }
    case RESET_KYC: {
      return state
    }
    case APPLICATION_DETAILS_DELETE: {
      return {
        ...state,
        applicationDetails: null,
      }
    }
    case APPLICATION_DETAILS_SAVE: {
      const { payload } = action
      return {
        ...state,
        applicationDetails: payload,
      }
    }
    case SET_PARTNER_DETAILS: {
      return {
        ...state,
        partner: action.payload,
      }
    }
    case SET_FUND_DETAILS: {
      const { payload } = action
      return {
        ...state,
        fund: payload,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
