import { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'
// import { industryOptions } from "./Step1Sub3";
const Step1Sub4: FC<any> = ({ submitLoanComplete, backToLoanEligibility, updateApplication }) => {
  // const [emi, setEmi] = useState<any>(0);
  // const [interest, setInterest] = useState<any>(0);
  const [debtRatio, setDebtRatio] = useState<any>(0)
  const [timeInBusiness, setTimeInBusiness] = useState<any>(0)

  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)
  const loanEligibility = useSelector((state: any) => state.kycApplication.loanEligibility)

  useEffect(() => {
    if (loanApplication && loanEligibility) {
      const {
        // industryType,
        profit,
        totalMonthlyInstallmentsToBanks,
        totalMonthlyInstallmentsToNonBanks,
        totalRevolvingCreditOutstandingToBanks,
        totalRevolvingCreditOutstandingToNonBanks,
        dateOfIncorporation,
      } = loanEligibility

      /* const incomeMargin = 0.1; // 10%in percentange
      const existingDebt = totalMonthlyInstallmentsToBanks + totalMonthlyInstallmentsToNonBanks + totalRevolvingCreditOutstandingToBanks * 0.05 + totalRevolvingCreditOutstandingToNonBanks  * 0.05 ;
      const principal = amountRequested;
      const actualIntrestRate = Number(industryOptions.find((o:any) => o.value === industryType)?.pricing);

      // Monthly interest rate : Divide by 100 to convert to fraction
      const interestRate = actualIntrestRate/1200 ; 
      const tenure = durationInMonths
      //EMI = P × r × (1 + r) n/ ((1 + r) n - 1) where P= Loan amount, r= interest rate, n=tenure in number of months
      const top = Math.pow((1+interestRate), tenure); 
      const bottom = top-1;
      const ratio = top/bottom
      const emi = principal * interestRate * ratio; */

      // Monthly Net Operating Income
      const existingDebt =
        totalMonthlyInstallmentsToBanks +
        totalMonthlyInstallmentsToNonBanks +
        totalRevolvingCreditOutstandingToBanks * 0.05 +
        totalRevolvingCreditOutstandingToNonBanks * 0.05
      const netOperatingIncome = profit / 12
      const totalDebts = existingDebt + loanApplication.emi
      const debtRatio = (totalDebts / netOperatingIncome) * 100

      // setInterest(loanApplication.interestRate);
      // setEmi(loanApplication.emi);
      setDebtRatio(debtRatio)
      const diffYear = Math.ceil(
        (new Date().getTime() - new Date(dateOfIncorporation).getTime()) /
          (1000 * 365 * 60 * 60 * 24)
      )
      setTimeInBusiness(diffYear)

      console.log('debtRatio: ' + debtRatio)
      console.log(diffYear)
    }
  }, [loanApplication, loanEligibility])

  return (
    <div className='d-flex flex-column'>
      <h1>{debtRatio <= 85 && timeInBusiness > 2 ? 'Congratulations' : 'Sorry'}</h1>

      {debtRatio <= 85 && timeInBusiness > 2 && (
        <div className='sub-heading mb-10 fs-5'>
          {`Hi  ${loanApplication?.applicantName || 'Applicant Name'}. We are excited to inform you 
          that ${
            loanEligibility?.companyName || 'Company name'
          } maybe eligible for a loan amount of 
          upto SG$ ${
            loanApplication
              ? loanApplication.amountRequested
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')
              : 'XX,XXX,XXX'
          } based 
          on the declarations made by you.`}
        </div>
      )}

      {/* Not eligible if debt ration > 85% or business is less than 2 years old */}
      {!(debtRatio <= 85 && timeInBusiness > 2) && (
        <div className='sub-heading mb-10 fs-5'>
          {`Hi ${loanApplication?.applicantName}, 
          We are sorry to inform that "${loanEligibility?.companyName}" may not be eligible for a business loan as it does not fit minimum eligibility rules based on the declarations made by you. 
          For any queries please feel free to email us at loans@credilinq.ai  with the application reference.`}
        </div>
      )}

      <div className='d-flex flex-column mt-5'>
        <div className='fs-3 fw-bolder mb-5'>
          You are just 2 more steps away from your submission of loan application
        </div>
      </div>

      <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-5'>
        <KTSVG
          path='/media/icons/duotune/general/gen044.svg'
          className='svg-icon-2x svg-icon-warning svg-icon-warning me-4'
        />
        <div className='d-flex flex-stack flex-grow-1'>
          <div className='fw-bold'>
            <h4 className='text-gray-800 fw-bolder'>Business Loan's Tip</h4>
            <div className='fs-6 text-gray-600'>
              We want you to complete the application submission in a flash and Business Loan
              Application recommends that you prepare few documents ready before you proceed:
            </div>
          </div>
        </div>
      </div>

      <div className='text-center'>
        <img alt='' src={toAbsoluteUrl('/media/alexi/congratulation.png')} className='mw-75' />
      </div>

      <div className='d-flex flex-end'>
        <div>
          <button className='btn btn-lg btn-secondary me-3' onClick={() => backToLoanEligibility()}>
            Recheck Eligibility
          </button>
          {debtRatio <= 85 && timeInBusiness > 2 && (
            <button className='btn btn-lg btn-primary me-3' onClick={() => submitLoanComplete()}>
              Save and Continue <RightAeroIcon color='currentColor' />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export { Step1Sub4 }
