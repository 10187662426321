import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PinInput from 'react-pin-input'

import { KTSVG } from '../../../../_metronic/helpers'
import { fetchStart } from '../../../../redux/actions/common'

import { Modal } from 'react-bootstrap-v5'
import { OtpIcon } from '../../../../svg/OtpIcon'
import UserVerification from '../UserVerification'
import { setOpenVerification, setSubStep } from '../redux/actions'

const Step1Sub2: FC<any> = ({ mobileNo, sendOTP, validateOTP, id, show, handleClose }) => {
  const dispatch = useDispatch()

  const [otp, setOtp] = useState<any>('')
  const openVerification = useSelector((state: any) => state.coApplicant.openVerification)

  // const [validateOTPMutation, { data, loading, error }] = useMutation(mutations.VALIDATE_OTP, { variables: { otp: otp, mobileNo: localStorage.getItem("mobileNo"), isApplicantOrShareholder: true } });

  const resendOTP = () => {
    dispatch(fetchStart())
    sendOTP({
      variables: {
        mobileNo,
        isApplicantOrShareholder: false,
      },
    })
  }

  // const submitOTP = () => {
  //   if (otp.length === 6) {
  //     dispatch(fetchStart());
  //     validateOTP({
  //       variables: {
  //         otp,
  //         mobileNo,
  //         isApplicantOrShareholder: false,
  //         applicantId: id
  //       }
  //     })
  //   }
  // }

  const onSubmitOTP = (otp: string) => {
    dispatch(fetchStart())
    validateOTP({
      variables: {
        otp,
        mobileNo,
        isApplicantOrShareholder: false,
        applicantId: id,
      },
    })
  }

  return (
    <>
      <Modal
        className='bg-transparent'
        id='kt_mega_menu_modal'
        data-backdrop='static'
        aria-hidden='true'
        role='dialog'
        tabIndex='-1'
        contentClassName='shadow-none'
        show={show}
        onHide={handleClose}
        centered // Bootstrap's built-in class for centering modals
      >
        <div className='d-flex flex-column flex-center flex-column-fluid'>
          <div className='mt-12 d-flex flex-column align-items-center'>
            {/* Heading and Sub-Text */}
            <OtpIcon />
            <h2 className='section-heading fs-2 text-center'>Verification</h2>
            <div className='text-gray-400 mb-20 fs-4 text-center'>
              Enter the verification code we
              <br /> sent to{' '}
              {mobileNo && mobileNo.replace(/[^\w ]/g, '').replace(/\d(?=.*\d{4})/g, '*')}
            </div>

            {/* Enter Code */}
            <div className='w-100 mb-4 text-start'>
              <div className='fs-4'>Enter Code</div>
              <PinInput
                length={6}
                initialValue=''
                secret
                focus={true}
                onChange={(value, index) => setOtp(value)}
                type='numeric'
                inputMode='number'
                style={{ borderRadius: '4px !important' }}
                inputStyle={{ borderColor: '#E8EAEB' }}
                inputFocusStyle={{ borderColor: '#E8EAEB' }}
                // onComplete={(value, index) => onSubmitOTP(value)}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>

            {/* Resend Code Section */}
            <div className='w-100 mb-10 text-start fs-6'>
              Didn’t get the code?{' '}
              <span
                className='nav-link inline-block cursor-pointer text-primary-dark'
                onClick={() => resendOTP()}
              >
                Resend Code
              </span>
            </div>

            {/* Action Buttons (Verify & Back) */}
            <div className='d-flex flex-column gap-4 w-100 mb-10 justify-content-between'>
              <button
                type='button'
                className='button button-primary'
                disabled={otp.length < 6} // Disable until OTP is filled
                onClick={() => onSubmitOTP(otp)}
              >
                Verify
              </button>
              <div className='line d-flex justify-content-center text-muted'>
                <span className='zIndex-1'>OR</span>
              </div>

              {/* Back Button */}
              <button
                type='button'
                className='button button-outline-back'
                onClick={handleClose} // Handle back action here
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {openVerification && (
        <UserVerification
          show={openVerification}
          handleClose={() => {
            dispatch(setOpenVerification(false))
          }}
          goToStep={() => {
            dispatch(setSubStep(3))
          }}
        />
      )}
    </>
  )
}

export { Step1Sub2 }
