// import {format, subMonths} from 'date-fns'
import dayjs from 'dayjs'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const linkStyle = {
  color: '#601A79',
  marginLeft: '10px',
  textDecoration: 'none',
}

interface FileUploadInfoProps {
  validateMonthsTill?: number
}

const FileUploadInfo: FunctionComponent<FileUploadInfoProps> = ({ validateMonthsTill = 6 }) => {
  const [endMonth, setEndMonth] = useState('')
  const [startMonth, setStartMonth] = useState('')
  useEffect(() => {
    setEndMonth(dayjs().subtract(1, 'month').format('MMM YY'))
    setStartMonth(dayjs().subtract(validateMonthsTill, 'month').format('MMM YY'))
  }, [])

  return (
    <div>
      <ul className='fs-4'>
        <li>
          <span>
            Please upload PDF copies (not scanned images) of your company's operating bank current
            account statements for the past {validateMonthsTill} months.
            <br /> For example, if today is <b>{dayjs().format('DD MMM YYYY')}</b>, upload
            statements from{' '}
            <b>
              {startMonth} to {endMonth}
            </b>{' '}
            (inclusive)
          </span>
        </li>
        <li>
          If your company uses multiple banks, provide {validateMonthsTill} months of statements for
          each account.
        </li>
        <li>
          Ensure the files are <b>not password-protected</b> to avoid submission issues.
        </li>
        <li>
          <li>
            For any difficulties with uploading, contact us at&nbsp;
            <a href='mailto:loans@credilinq.ai' className='text-primary-dark'>
              loans@credilinq.ai
            </a>
          </li>
        </li>
      </ul>
    </div>
  )
}

export default FileUploadInfo
