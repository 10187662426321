import { SVGProps } from 'react'

export function SubmitIcon({
  color = 'var(--primary-color)',
  fontSize = '32',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 32 32'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <rect
        width='32'
        height='32'
        rx='16'
        fill='url(#paint0_linear_28006_17660)'
        fill-opacity='0.1'
      />
      <g clip-path='url(#clip0_28006_17660)'>
        <path
          d='M16 8C11.5886 8 8 11.5886 8 16C8 20.4114 11.5886 24 16 24C20.4114 24 24 20.4114 24 16C24 11.5886 20.4114 8 16 8ZM20.0547 14.3047L15.7213 18.6379C15.5913 18.7679 15.4207 18.8334 15.25 18.8334C15.0793 18.8334 14.9087 18.7679 14.7787 18.6379L12.6121 16.4713C12.3513 16.2107 12.3513 15.7893 12.6121 15.5287C12.8727 15.2679 13.2939 15.2679 13.5547 15.5287L15.25 17.224L19.1121 13.3621C19.3727 13.1013 19.7939 13.1013 20.0547 13.3621C20.3153 13.6227 20.3153 14.0439 20.0547 14.3047Z'
          fill='url(#paint1_linear_28006_17660)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_28006_17660'
          x1='-7.52381'
          y1='16'
          x2='31.9295'
          y2='17.6684'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_28006_17660'
          x1='4.2381'
          y1='16'
          x2='23.9647'
          y2='16.8342'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <clipPath id='clip0_28006_17660'>
          <rect width='16' height='16' fill='white' transform='translate(8 8)' />
        </clipPath>
      </defs>
    </svg>
  )
}
