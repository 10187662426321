import { SVGProps } from 'react'

export function RightAeroIcon({
  color = 'var(--text-color-2)',
  fontSize = '20',
  style = {},
  onClick
}: SVGProps<SVGElement>) {
  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.1464 6.31442C12.3417 6.11915 12.6583 6.11915 12.8535 6.31442L16.1868 9.64775C16.2806 9.74152 16.3333 9.86869 16.3333 10.0013C16.3333 10.1339 16.2806 10.2611 16.1868 10.3549L12.8535 13.6882C12.6583 13.8835 12.3417 13.8835 12.1464 13.6882C11.9511 13.4929 11.9511 13.1763 12.1464 12.9811L14.6262 10.5013H4.16663C3.89048 10.5013 3.66663 10.2774 3.66663 10.0013C3.66663 9.72516 3.89048 9.5013 4.16663 9.5013H14.6262L12.1464 7.02152C11.9511 6.82626 11.9511 6.50968 12.1464 6.31442Z'
        fill={color}
      />
    </svg>
  )
}
