import { SVGProps } from 'react'

export function VerifyIcon({
  color = 'var(--primary-color)',
  fontSize = '32',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 32 32'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <rect
        width='32'
        height='32'
        rx='16'
        fill='url(#paint0_linear_28006_17650)'
        fill-opacity='0.1'
      />
      <path
        d='M14.689 7.50391C13.6763 7.50391 12.705 7.90622 11.9889 8.62235C11.2727 9.33847 10.8704 10.3098 10.8704 11.3225C10.8704 12.3353 11.2727 13.3065 11.9889 14.0227C12.705 14.7388 13.6763 15.1411 14.689 15.1411C15.7018 15.1411 16.6731 14.7388 17.3892 14.0227C18.1053 13.3065 18.5076 12.3353 18.5076 11.3225C18.5076 10.3098 18.1053 9.33847 17.3892 8.62235C16.6731 7.90622 15.7018 7.50391 14.689 7.50391ZM15.0688 17.006C15.2838 16.6942 15.0773 16.2184 14.6992 16.2184H13.6822C12.1752 16.2184 10.7299 16.8171 9.66429 17.8827C8.59866 18.9483 8 20.3936 8 21.9007C8 22.5158 8.24437 23.1058 8.67935 23.5408C9.11433 23.9757 9.7043 24.2201 10.3195 24.2201H14.7544C15.1385 24.2201 15.3441 23.7324 15.1215 23.4206C14.4454 22.4736 14.0829 21.3386 14.085 20.1751C14.085 18.9992 14.4486 17.9066 15.0688 17.006Z'
        fill='url(#paint1_linear_28006_17650)'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M24 20.1758C24 21.3216 23.5448 22.4205 22.7346 23.2307C21.9244 24.0409 20.8255 24.4961 19.6797 24.4961C18.5339 24.4961 17.435 24.0409 16.6248 23.2307C15.8145 22.4205 15.3594 21.3216 15.3594 20.1758C15.3594 19.03 15.8145 17.9311 16.6248 17.1209C17.435 16.3106 18.5339 15.8555 19.6797 15.8555C20.8255 15.8555 21.9244 16.3106 22.7346 17.1209C23.5448 17.9311 24 19.03 24 20.1758ZM22.1529 18.5335C22.2159 18.5886 22.2675 18.6556 22.3046 18.7307C22.3417 18.8058 22.3636 18.8874 22.3691 18.971C22.3747 19.0546 22.3637 19.1384 22.3368 19.2177C22.3099 19.297 22.2676 19.3702 22.2124 19.4332L20.0289 21.9251C19.9327 22.0348 19.8149 22.1234 19.683 22.1856C19.551 22.2477 19.4077 22.282 19.2619 22.2863C19.1161 22.2906 18.971 22.2648 18.8356 22.2106C18.7002 22.1563 18.5774 22.0748 18.4749 21.971L17.2489 20.7306C17.1901 20.671 17.1435 20.6004 17.1119 20.5228C17.0804 20.4453 17.0644 20.3622 17.0649 20.2785C17.0654 20.1947 17.0824 20.1119 17.1149 20.0347C17.1475 19.9576 17.1949 19.8876 17.2544 19.8287C17.314 19.7698 17.3846 19.7233 17.4622 19.6917C17.5397 19.6602 17.6228 19.6442 17.7065 19.6447C17.7903 19.6452 17.8731 19.6622 17.9503 19.6947C18.0274 19.7272 18.0975 19.7746 18.1563 19.8342L18.9006 20.5887C18.9416 20.6302 18.9907 20.6629 19.045 20.6846C19.0992 20.7063 19.1572 20.7166 19.2156 20.7148C19.274 20.7131 19.3313 20.6993 19.3841 20.6744C19.4369 20.6495 19.484 20.6139 19.5225 20.57L21.2532 18.5938C21.3083 18.5308 21.3753 18.4792 21.4504 18.4421C21.5255 18.405 21.6071 18.3831 21.6907 18.3775C21.7743 18.372 21.8581 18.383 21.9374 18.4099C22.0167 18.4368 22.0899 18.4782 22.1529 18.5335Z'
        fill='url(#paint2_linear_28006_17650)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_28006_17650'
          x1='-7.52381'
          y1='16'
          x2='31.9295'
          y2='17.6684'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_28006_17650'
          x1='5.52946'
          y1='15.862'
          x2='18.4985'
          y2='16.2067'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_28006_17650'
          x1='13.3278'
          y1='20.1758'
          x2='23.9809'
          y2='20.6263'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
      </defs>
    </svg>
  )
}
