/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation, ServerError, useLazyQuery } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { fetchStart, fetchStop } from '../../../../redux/actions/common'
import mutations from '../../../../setup/graphql/mutations'
import query from '../../../../setup/graphql/query'
import Alert from '../../../../_metronic/partials/alert'
import { setApplicationComplete, setCoApplicant } from '../redux/actions'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { toStr } from '../../loanApplication/steps/Step3'
import PhoneInput from 'react-phone-number-input'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'

const Step1Sub3: FC<any> = ({ getCoApplicant }) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  localStorage.removeItem('singpass')

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  // const [information, setInformation] = useState<any>(null);

  const [saveUserData, onSaveUserData] = useMutation(mutations.CREATE_EXTERNAL_DATA_STORE)
  const [updateCoApplicant, onUpdateCoApplicant] = useMutation(mutations.UPDATE_SHAREHOLDER_KYC)
  const [fetchBrigs, onSuccessFetchBrigs] = useLazyQuery(query.FETCH_BRIGS_DATA)

  const companyVerificationMethod = useSelector(
    (state: any) => state.coApplicant.companyVerificationMethod
  )
  // const coapplicant = useSelector((state: any) => state.coApplicant.co_applicant);
  const person = useSelector((state: any) => state.coApplicant.personData)

  const coApplicationId = localStorage.getItem('coApplicationId')
  const coApplicant = useSelector((state: any) => state.coApplicant.co_applicant)

  useEffect(() => {
    if (coApplicant == null) {
      getCoApplicant({ variables: { data: { id: localStorage.getItem('coApplicationId') } } })
    }
  }, [])
  useEffect(() => {
    if (onSaveUserData.called && !onSaveUserData.loading) {
      if (onSaveUserData.data) {
        if (coApplicant.isGuarantor) {
          fetchBrigs({
            variables: {
              coApplicationId: coApplicationId,
            },
          })
        } else {
          updateCoApplicant({
            variables: {
              where: {
                id: coApplicationId,
              },
              data: {
                experianConsent: false,
                kycStatus: 'completed',
              },
            },
          })
        }
      }

      if (onSaveUserData.error) {
        const { networkError } = onSaveUserData.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSaveUserData.data, onSaveUserData.error, onSaveUserData.loading])

  useEffect(() => {
    if (onSuccessFetchBrigs.called && !onSuccessFetchBrigs.loading) {
      if (onSuccessFetchBrigs.data) {
        updateCoApplicant({
          variables: {
            where: {
              id: coApplicationId,
            },
            data: {
              experianConsent: true,
              kycStatus: 'completed',
            },
          },
        })
      }

      if (onSuccessFetchBrigs.error) {
        const { networkError } = onSuccessFetchBrigs.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessFetchBrigs.data, onSuccessFetchBrigs.error, onSuccessFetchBrigs.loading])

  useEffect(() => {
    if (onUpdateCoApplicant.called && !onUpdateCoApplicant.loading) {
      if (onUpdateCoApplicant.data) {
        dispatch(fetchStop())
        dispatch(setCoApplicant(onUpdateCoApplicant.data.updateShareholderKycStatus))
        dispatch(setApplicationComplete(true))
        history.push(`/co-applicant/completed`)
      }

      if (onUpdateCoApplicant.error) {
        const { networkError } = onUpdateCoApplicant.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onUpdateCoApplicant.data, onUpdateCoApplicant.error, onUpdateCoApplicant.loading])

  const initialValues: any = {
    uinfin: person?.uinfin?.value ?? '',
    name: person?.name?.value ?? '',
    sex: person?.sex?.desc ?? '',
    // race: person.race.desc,
    nationality: person?.nationality?.desc ?? '',
    dob: person?.dob?.value ?? '',
    email: person?.email?.value ?? '',
    mobileno: person
      ? `${person?.mobileno?.prefix?.value}${person?.mobileno?.areacode?.value}${person?.mobileno?.nbr?.value}`
      : '',
    // regadd: person ? `${person.regadd.block?.value} ${person.regadd.building?.value} ${person.regadd.floor?.value} ${person.regadd.unit?.value} ${person.regadd.street?.value} ${person.regadd.postal?.value}` : '',
    regadd: person ? toStr(person.regadd, 'ADDRESSLOCAL') : '',
    // housingtype: `${person.housingtype.desc} ${person.hdbtype.desc}`,
    // marital: person.marital.desc,
    // edulevel: person.edulevel.desc,
    residentialstatus: person?.residentialstatus?.desc ?? '',
    ownerprivate: person?.ownerprivate?.value ?? '',
    noahistory: person?.noahistory?.noas || [],
    confirmCheckbox: coApplicant?.isGuarantor ? false : true,
  }
  const validationSchema = Yup.object().shape({
    uinfin: Yup.string(),
    name: Yup.string(),
    sex: Yup.string(),
    nationality: Yup.string(),
    dob: Yup.string(),
    email: Yup.string().required('Email Address is required').email('Enter Valid Email'),
    mobileno: Yup.string()
      .min(11, 'Enter Valid Mobile Number')
      .max(11, 'Enter Valid Mobile Number')
      .required('Mobile Number is required'),
    regadd: Yup.string(),
    residentialstatus: Yup.string(),
    ownerprivate: Yup.string(),
    confirmCheckbox: Yup.bool().oneOf([true], 'The checkbox must be checked'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      if (coApplicationId) {
        dispatch(fetchStart())
        saveUserData({
          variables: {
            data: {
              shareholderKycStatus: {
                connect: {
                  id: coApplicationId,
                },
              },
              //TODO: add data fetched from singpass or manual entry here
              type: 'singpass',
              data: values,
              // dataCreatedAt: new Date().toISOString()
            },
          },
        })
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='d-flex flex-column flex-column-fluid w-100'>
      <div className='notice d-flex banner text-start p-6 mb-5'>
        <div className='d-flex flex-stack flex-grow-1'>
          <div>
            <h2>User Profile</h2>
            <div className='fs-3'>
              The following details have been provided to us by MyInfo Singpass
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='cardNew'>
          <div className='section-heading'></div>
          <h2>Personal Information</h2>
          <hr className='text-gray-400' />
          <div className='row'>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                UINFIN
              </label>
              <div className='col-sm-12'>
                <input
                  name='uinfin'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.uinfin}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.uinfin && formik.errors.uinfin && (
                  <div className='text-danger mt-2'>{formik.errors.uinfin}</div>
                )}
              </div>
            </div>

            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Name
              </label>
              <div className='col-sm-12'>
                <input
                  name='name'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='text-danger mt-2'>{formik.errors.name}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Sex
              </label>
              <div className='col-sm-12'>
                <input
                  name='sex'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.sex}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.sex && formik.errors.sex && (
                  <div className='text-danger mt-2'>{formik.errors.sex}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Date of birth
              </label>
              <div className='col-sm-12'>
                <input
                  name='dob'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.dob && formik.errors.dob && (
                  <div className='text-danger mt-2'>{formik.errors.dob}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Mobile number
              </label>
              <div className='col-sm-12'>
                <PhoneInput
                  id='mobileno'
                  international
                  defaultCountry='SG'
                  value={formik.values.mobileno}
                  onChange={(value) => formik.setFieldValue('mobileno', value)}
                  className='int-phone-input rounded-top rounded-bottom'
                />
                {formik.touched.mobileno && formik.errors.mobileno && (
                  <div className='text-danger mt-2'>{formik.errors.mobileno}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Email
              </label>
              <div className='col-sm-12'>
                <input
                  name='email'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  // disabled={companyVerificationMethod === 1}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='text-danger mt-2'>{formik.errors.email}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Nationality
              </label>
              <div className='col-sm-12'>
                <input
                  name='nationality'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.nationality}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.nationality && formik.errors.nationality && (
                  <div className='text-danger mt-2'>{formik.errors.nationality}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Residential Status
              </label>
              <div className='col-sm-12'>
                <input
                  name='residentialstatus'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.residentialstatus}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.residentialstatus && formik.errors.residentialstatus && (
                  <div className='text-danger mt-2'>{formik.errors.residentialstatus}</div>
                )}
              </div>
            </div>

            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Registered Address
              </label>
              <div className='col-sm-12'>
                <input
                  name='regadd'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.regadd}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.regadd && formik.errors.regadd && (
                  <div className='text-danger mt-2'>{formik.errors.regadd}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Owner Private
              </label>
              <div className='col-sm-12'>
                <input
                  name='ownerprivate'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.ownerprivate}
                  onChange={formik.handleChange}
                  disabled={companyVerificationMethod === 1}
                />
                {formik.touched.ownerprivate && formik.errors.ownerprivate && (
                  <div className='text-danger mt-2'>{formik.errors.ownerprivate}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {coApplicant?.isGuarantor && (
          <div className='cardNew'>
            <div className='section-heading'>
              <h2>Acknowledgement</h2>
              <hr className='text-gray-400' />
            </div>
            <div>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  name='confirmCheckbox'
                  type='checkbox'
                  className='form-check-input'
                  checked={formik.values.confirmCheckbox}
                  onChange={formik.handleChange}
                />
                <span className='fs-5'>
                  I acknowledge that I have read and understood the above Data Protection Notice,
                  and consent to the collection, use and disclosure of my personal data by CrediLinq
                  A.I Pte Ltd and AsiaLinq Investments Pte Ltd for the purposes set out in the{' '}
                  <a
                    href={toAbsoluteUrl('/files/notice.pdf')}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-primary-dark'
                  >
                    Notice 
                  </a>
                </span>
              </label>
              {formik.touched.confirmCheckbox && formik.errors.confirmCheckbox && (
                <div className='text-danger mt-2'>{formik.errors.confirmCheckbox}</div>
              )}
            </div>
          </div>
        )}

        <div className='cardNew stickeyFooter'>
          <div className='d-flex flex-end'>
            <button type='submit' className='button button-primary '>
              Submit KYC <RightAeroIcon color='currentColor' />
            </button>
          </div>
        </div>
      </form>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export { Step1Sub3 }
