import { SVGProps } from 'react'
export function Step5Icon({
  color = 'var(--text-color-2)',
  fontSize = '24',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        style={style}
        onClick={onClick}
        width={fontSize}
        height={fontSize}
      >
        <path
          d='M21.8014 7.8436C21.3181 7.35993 20.5497 7.32953 20.0301 7.7529C19.955 7.81414 20.0132 7.76018 18.8692 8.90421L21.8388 11.8739L22.9061 10.8199C23.4221 10.3039 23.4221 9.46434 22.9061 8.94828L21.8014 7.8436ZM12.7794 15.0502L11.0325 18.7936C10.9751 18.9166 10.9569 19.0544 10.9805 19.1882C11.004 19.322 11.0682 19.4453 11.1642 19.5413C11.2603 19.6373 11.3836 19.7015 11.5173 19.7251C11.6511 19.7486 11.7889 19.7305 11.912 19.673L15.6553 17.9261C15.8034 17.857 15.3551 18.2766 20.8971 12.8038L17.9333 9.83998C12.4385 15.3349 12.8499 14.8992 12.7794 15.0502Z'
          fill={color}
        />
        <path
          d='M16.7703 18.7399C16.6087 18.8995 16.4209 19.0302 16.2151 19.1261L12.4718 20.873C11.7357 21.2165 10.8364 21.0857 10.2286 20.4778C9.64054 19.8898 9.48169 18.9883 9.83337 18.2346L11.5803 14.4911C11.678 14.2815 11.8118 14.0906 11.9754 13.927L17.603 8.29945V2.6922C17.603 1.59758 16.7125 0.707031 15.6178 0.707031H2.6922C1.59758 0.707031 0.707031 1.59758 0.707031 2.6922V21.3086C0.707031 22.4033 1.59758 23.2938 2.6922 23.2938H15.6178C16.7125 23.2938 17.603 22.4033 17.603 21.3086V17.9175L16.7703 18.7399ZM4.23626 4.98617H14.118C14.4834 4.98617 14.7797 5.28244 14.7797 5.64789C14.7797 6.01334 14.4834 6.30961 14.118 6.30961H4.23626C3.87081 6.30961 3.57454 6.01334 3.57454 5.64789C3.57454 5.28244 3.87081 4.98617 4.23626 4.98617ZM4.23626 8.51535H12.0005C12.3659 8.51535 12.6622 8.81163 12.6622 9.17707C12.6622 9.54252 12.3659 9.8388 12.0005 9.8388H4.23626C3.87081 9.8388 3.57454 9.54252 3.57454 9.17707C3.57454 8.81163 3.87081 8.51535 4.23626 8.51535ZM3.57454 12.7063C3.57454 12.3408 3.87081 12.0445 4.23626 12.0445H9.88296C10.2484 12.0445 10.5447 12.3408 10.5447 12.7063C10.5447 13.0717 10.2484 13.368 9.88296 13.368H4.23626C3.87081 13.368 3.57454 13.0717 3.57454 12.7063Z'
          fill={color}
        />
      </svg>
    </>
  )
}
