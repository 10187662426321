import React, { FunctionComponent, useEffect, useState } from 'react'

import { nanoid } from 'nanoid'

import { cancelPerfiosTransaction, initiatePerfiosTransaction } from './utils/perfiosTransaction'
import FailedBankStatement from './FailedBankStatement/FailedBankStatement'
import type { Banks, UploadedBankStmtRes } from './types/upload-file-response'
import dayjs from 'dayjs'
import { Stack } from 'react-bootstrap'
import { Button, FormGroup, Modal } from 'react-bootstrap-v5'
import { UploadDropZone } from './UploadDropZone/UploadDropZone'
import FileUploadInfo from './FileUploadInfo/FileUploadInfo'
import UploadBankStatement from './UploadBankStatement/UploadBankStatement'
import { KTSVG } from '../../_metronic/helpers'
import { ReUploadComponent } from './ReUploadComponent/ReUploadComponent'
import { Xicon } from '../../svg/Xicon'
import { setLoanApplication } from '../../app/modules/loanApplication/redux/actions'
import { useLazyQuery } from '@apollo/client'
import query from '../../setup/graphql/query'
import { useDispatch } from 'react-redux'
import { fetchStop } from '../../redux/actions/common'

interface BankStatementValidatorProps {
  disabled?: boolean
  validateMonthsTill?: number
  refId: string
  refType: string
  setUploadedCorrectStatements: any
  setUploadedFiles: any
  isBankReportGenerated: boolean
  setIsBankReportGenerated: any
  removeAllUploadedStatements: any
}

type LocalStateUIFileType = File & { key: string; response?: UploadedBankStmtRes; error?: string }

const BankStatementValidator: FunctionComponent<BankStatementValidatorProps> = ({
  disabled = false,
  validateMonthsTill = 6,
  refId,
  refType,
  setUploadedCorrectStatements,
  setUploadedFiles,
  isBankReportGenerated,
  setIsBankReportGenerated,
  removeAllUploadedStatements,
}) => {
  const today = new Date()
  let d
  const last6Months = new Array(validateMonthsTill)
    .fill(0)
    .map((_, i) => {
      d = new Date(today.getFullYear(), today.getMonth() - i - 1, 1)
      return dayjs(new Date(d)).format('MMM')
    })
    .reverse()
  const dispatch = useDispatch()
  const [files, setFiles] = useState<Array<LocalStateUIFileType>>([])
  const [processedFiles, setProcessedFiles] = useState<Array<LocalStateUIFileType>>([])
  const [accounts, setAccounts] = useState<Banks[]>([])
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(false)

  const [fileResponseStatus, setFileResponseStatus] = useState<any[]>([])

  const [successFileRes, setSuccessFileRes] = useState<any[]>([])

  const [reuploadWarningModal, setReuploadWarningModal] = useState<boolean>(false)
  // const [reuploadFlag, setReuploadFlag] = useState<boolean>(isBankReportGenerated || false)

  useEffect(() => {
    initiatePerfiosTransaction(refId, refType, validateMonthsTill)
  }, [])
  // const [openError, setOpenError] = useState(false);
  /**
   * It removes the file from the state and the formik res state.
   * @param {string} key - string - the key of the file to be removed
   */
  const handleClose = (key: string) => {
    const _files = files.filter((file) => file.key !== key)

    const _fileResponse = fileResponseStatus.filter((eachFile) => eachFile?.file?.key !== key)
    setFileResponseStatus(_fileResponse)
    setFiles(_files)
    setUploadDisabled(false)
    setUploadedFiles(_files)
    if (_files?.length === 0) {
      handleCloseAll()
    }

    // const uploadedLast6Months = updatedAccounts.every((account) => account.months.length == 0)
    // setUploadedCorrectStatements(uploadedLast6Months)
  }

  const handleCloseAll = () => {
    setFiles([])
    setUploadedCorrectStatements(false)
    setUploadedFiles([])
    setProcessedFiles([])
    setFileResponseStatus([])
    setAccounts([])
    setUploadDisabled(false)
    window.localStorage.setItem('accountDetails', JSON.stringify([]))
    window.localStorage.setItem('uploadedFiles', JSON.stringify(0))
    cancelPerfiosTransaction(refType)
    initiatePerfiosTransaction(refId, refType, validateMonthsTill)
  }

  const calculateAccountDetails = async (res: Banks[]) => {
    const accountDetails = JSON.parse(
      window.localStorage.getItem('accountDetails') as string
    ) as Banks[]

    for (const eachBank of res) {
      const findBankAccount = accountDetails.find((ac: Banks) => ac.accountNo == eachBank.accountNo)
      const filteredAccounts = accountDetails.filter(
        (ac: Banks) => ac.accountNo != eachBank.accountNo
      )
      if (!findBankAccount) {
        const newAccount: Banks = {
          account: eachBank.account ? eachBank.account : 'Account Number',
          accountNo: eachBank.accountNo,
          months: eachBank.months,
          completed: eachBank.months.length ? false : true,
        }
        accountDetails.push(newAccount)
        window.localStorage.setItem('accountDetails', JSON.stringify(accountDetails))
      } else if (findBankAccount && !findBankAccount.completed) {
        const updateAccount: Banks = {
          account: eachBank.account ? eachBank.account : findBankAccount.account,
          accountNo: eachBank.accountNo,
          months: eachBank.months.length
            ? findBankAccount.months.filter((value: string) => eachBank.months.includes(value))
            : [],
          completed: eachBank.months.length ? false : true,
        }

        window.localStorage.setItem(
          'accountDetails',
          JSON.stringify([...filteredAccounts, updateAccount])
        )
      }
    }

    const updatedAccounts = JSON.parse(
      window.localStorage.getItem('accountDetails') as string
    ) as Banks[]
    const uploadedLast6Months = updatedAccounts.every((account) => account.months.length == 0)
    setUploadedCorrectStatements(uploadedLast6Months)
    setAccounts(JSON.parse(window.localStorage.getItem('accountDetails') as string))
  }

  /**
   * It takes in a response from the server and a key generated inside onDrop function, and then adds the key to the response and pushes the response to the uploadedFilesRes array
   * @param res - UploadedBankStmtRes & { key: string }
   * @param {string} key - string - This is the key that will be used to remove the file from the uploadedFilesRes array when the close icon is clicked.
   */
  const handleUploadedFileRes = (res: UploadedBankStmtRes, key: string) => {
    setFiles((files) => {
      const index = files.findIndex((file) => file.key === key)
      if (index === -1) {
        return files
      }
      calculateAccountDetails(res.banks)
      if (index === 0) {
        const file = files[index]
        file.response = res
        const _files = [file, ...files.slice(1)]
        setUploadedFiles(_files)
        return _files
      } else {
        const file = files[index]

        file.response = res
        const _files = [...files.slice(0, index), file, ...files.slice(index + 1)]
        setUploadedFiles(_files)
        return _files
      }
    })
  }

  const handleFailedFiles = (
    file: any,
    status: any,
    error: any,
    res: UploadedBankStmtRes | null
  ) => {
    setFileResponseStatus((prev) => [
      ...prev,
      { file: file, status: 400, error: error, response: res },
    ])
    // setProcessedFiles(prev => [...prev, file]);
  }

  useEffect(() => {
    window.localStorage.setItem('accountDetails', JSON.stringify([]))
    window.localStorage.setItem('uploadedFiles', JSON.stringify(0))
  }, [])

  useEffect(() => {
    const filterOkFiles = fileResponseStatus.filter((item) => item.status == 200)
    if (fileResponseStatus.length && fileResponseStatus?.length == filterOkFiles?.length) {
      setUploadDisabled(false)
    }
  }, [fileResponseStatus])

  useEffect(() => {
    const filteredFiles = files.filter((f) => f && f?.response && Object.keys(f.response).length)
    const responses = filteredFiles
      .filter((f) => f && f?.response && Object.keys(f.response).length)
      .map((item) => item?.response)
  }, [successFileRes])

  useEffect(() => {
    const uploadedFiles = JSON.parse(window.localStorage.getItem('uploadedFiles') as string)
    if (files.length && uploadedFiles == files.length) {
      setUploadDisabled(false)
    }

    const accountdetails = JSON.parse(
      window.localStorage.getItem('accountDetails') as string
    ) as Banks[]
    const completedAccounts = accountdetails.filter((ac: Banks) => ac.completed == true)
    if (accountdetails.length == completedAccounts.length) {
      const updateFailedFiles = fileResponseStatus.map((eachFile) => {
        if (
          eachFile &&
          (eachFile?.error === undefined ||
            eachFile?.error?.toLowerCase().includes('internal server error') ||
            eachFile?.error?.toLowerCase().includes('internal error'))
        ) {
          const newResponse = eachFile?.response ? eachFile?.response : null

          setSuccessFileRes((prev) => [...prev, newResponse])

          return { file: eachFile.file, status: 200, error: null }
        }

        return eachFile
      })

      setFileResponseStatus([...updateFailedFiles])
    }
  }, [files])

  // const handleCloseSnackbar = (e?: React.SyntheticEvent | Event, reason?: string) => {
  //   setOpenError(false);
  // };
  /**
   * We're using the `nanoid` library to generate a unique key for each file, and then we're adding that key to the file object
   * @param acceptedFiles - Array<File>
   */
  const onDrop = (acceptedFiles: Array<File>) => {
    const acceptedFilesWithKey = acceptedFiles.map((f) => {
      ;(f as any).key = nanoid()
      if (files.map((f) => f.name).includes(f.name)) {
        ;(f as any).error = 'File already uploaded'
      }
      // setFileResponseStatus(prev => [...prev, { key: (f as any).key, status: 0, error: null }]);
      return f
    }) as Array<LocalStateUIFileType>
    // handle Form local state UI for files
    setUploadDisabled(true)
    setFiles([...files, ...acceptedFilesWithKey])
  }

  const reuploadAccepted = () => {
    setIsBankReportGenerated(false)
    setReuploadWarningModal(false)
    setFiles([])
    setUploadedCorrectStatements(false)
    setUploadedFiles([])
    setProcessedFiles([])
    setFileResponseStatus([])
    setAccounts([])
    setUploadDisabled(false)
    window.localStorage.setItem('accountDetails', JSON.stringify([]))
    window.localStorage.setItem('uploadedFiles', JSON.stringify(0))
    /**
     * Resetting Mail Sent Flag on Customer Tries to Reupload Whole Set, after one set is completed
     */
    window.localStorage.setItem('errorLogged', 'false')
    initiatePerfiosTransaction(refId, refType, validateMonthsTill)
    removeAllUploadedStatements(true)
  }

  // const onDropRejected = () => {
  //   setOpenError(true);
  //   setUploadDisabled(false);
  // };
  const asyncWait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  return (
    <>
      {/* <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={openError} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert severity="error">You can only upload 6 files at a time</Alert>
      </Snackbar> */}
      {isBankReportGenerated ? (
        <ReUploadComponent setReuploadWarningModal={setReuploadWarningModal} />
      ) : (
        <Stack direction='vertical' style={{ width: '100%', fontSize: '14px' }}>
          <div className='bank-statement-upload mb-3 rounded' style={{ width: '100%' }}>
            <FileUploadInfo />
          </div>
          <div>
            <UploadDropZone onDrop={onDrop} singleFiltSupprt='PDF' />
          </div>
        </Stack>
      )}
      <FormGroup style={{ color: 'primary.main', fontSize: '14px' }}>
        {accounts.flatMap((bank) => (
          <div key={bank.account + bank.accountNo}>
            <span style={{ display: 'block', marginBottom: '5px' }}>
              {bank.account} - {bank.accountNo}
            </span>
            {last6Months.flatMap((month) => (
              <>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={
                    !bank.months.map((m) => dayjs(new Date(m)).format('MMM')).includes(month)
                  }
                  id='flexCheckChecked'
                  key={nanoid()}
                  disabled={uploadDisabled}
                />
                <label className='form-check-label' style={{ margin: '0 15px 0 5px' }}>
                  {month}
                </label>
              </>
            ))}
            <span style={{ display: 'block', margin: '5px 0 15px 0', color: 'var(--error-red)' }}>
              {!last6Months.every(
                (month) => !bank.months.map((m) => dayjs(new Date(m)).format('MMM')).includes(month)
              ) && 'upload statement for remaining months'}
            </span>
          </div>
        ))}
      </FormGroup>
      <Stack style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '2' }}></Stack>

      {/* display: 'flex', marginTop:'20px', flexDirection:"row", gap:'15px', justifyContent: 'flex-start' */}
      {/* <Stack style={{flexDirection: 'row', flexWrap: 'wrap', gap: '2', marginTop: 2, display:'flex', }}> */}
      <Stack
        style={{
          display: 'table',
          marginBottom: '5px',
        }}
      >
        {files.map((file: LocalStateUIFileType) => {
          ;(async (file) => {
            await asyncWait(1000)
          })()
          return (
            <UploadBankStatement
              password=''
              refId={refId}
              refType={refType}
              validateMonthsTill={+validateMonthsTill}
              file={file}
              key={file.key}
              onClose={() => handleClose(file.key)}
              handleUploadedFileRes={(res) => handleUploadedFileRes(res, file.key)}
              error={file.error}
              handleFailedFiles={(file, status, error, res) =>
                handleFailedFiles(file, status, error, res)
              }
            />
          )
        })}
        {fileResponseStatus.map(
          ({
            file,
            status,
            error,
          }: {
            file: LocalStateUIFileType
            status: number
            error: string
          }) => {
            return (
              <FailedBankStatement
                file={file}
                key={file.key}
                error={error}
                status={status}
                onClose={() => handleClose(file.key)}
              />
            )
          }
        )}
      </Stack>

      {JSON.parse(window.localStorage.getItem('uploadedFiles') as string) >= 1 ? (
        <button className='button button-primary justify-content-start' onClick={handleCloseAll}>
          Remove All
        </button>
      ) : (
        <></>
      )}

      <Modal
        className='bg-transparent'
        id='kt_mega_menu_modal'
        data-backdrop='static'
        aria-hidden='true'
        role='dialog'
        tabIndex='-1'
        contentClassName='shadow-none'
        show={reuploadWarningModal}
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <div>
              <h3 className='fw-bold m-0'>
                Re-Upload Bank Statements
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-6'
                  data-bs-dismiss='modal'
                  style={{ float: 'right' }}
                  onClick={() => setReuploadWarningModal(false)}
                >
                  <Xicon />
                </div>
              </h3>
              <div className='mt-2 fs-5'>
                Are you sure you want to Re-Upload Bank Statements again? This will remove all of
                your previously uploaded statements.
              </div>
            </div>
          </div>
          <div className='d-flex flex-center mt-2 mb-3'>
            <button className='button button-primary me-3' onClick={reuploadAccepted}>
              I understand
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default BankStatementValidator
