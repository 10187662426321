import { SVGProps } from 'react'

export function AmountIcon({
  color = 'var(--primary-color)',
  fontSize = '33',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 32 33'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <rect
        y='0.5'
        width='32'
        height='32'
        rx='16'
        fill='url(#paint0_linear_28134_8401)'
        fill-opacity='0.1'
      />
      <circle cx='16' cy='16.5' r='8' fill='url(#paint1_linear_28134_8401)' />
      <path
        d='M15.0879 17.1426C15.0879 17.4966 15.3616 17.7813 15.6974 17.7813H16.3836C16.6756 17.7813 16.9129 17.5331 16.9129 17.2229C16.9129 16.8907 16.7669 16.7703 16.5515 16.6936L15.4529 16.3104C15.2375 16.2337 15.0915 16.1169 15.0915 15.7811C15.0915 15.4745 15.3288 15.2227 15.6208 15.2227H16.307C16.6428 15.2227 16.9165 15.5074 16.9165 15.8614'
        stroke='white'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.0005 14.8594V18.1444'
        stroke='white'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.6501 16.5016C19.6501 18.5164 18.0149 20.1516 16.0001 20.1516C13.9853 20.1516 12.3501 18.5164 12.3501 16.5016C12.3501 14.4868 13.9853 12.8516 16.0001 12.8516'
        stroke='white'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.6499 14.3116V12.8516H18.1899'
        stroke='white'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.8252 14.6766L19.6502 12.8516'
        stroke='white'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_28134_8401'
          x1='-7.52381'
          y1='16.5'
          x2='31.9295'
          y2='18.1684'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_28134_8401'
          x1='-18'
          y1='16.5'
          x2='24.5184'
          y2='16.5001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='var(--gradient-color-blue)' />
          <stop offset='0.269162' stop-color='var(--gradient-color-blue)' />
          <stop offset='0.522689' stop-color='var(--gradient-color-purple)' />
          <stop offset='0.702487' stop-color='var(--gradient-color-pink)' />
          <stop offset='0.848653' stop-color='var(--gradient-color-pink)' />
          <stop offset='1' stop-color='var(--gradient-color-orange)' />
        </linearGradient>
      </defs>
    </svg>
  )
}
