import { FC, useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ServerError, useMutation } from '@apollo/client'
import { Accordion } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '../../../../_metronic/partials/alert'
import mutations from '../../../../setup/graphql/mutations'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { fetchStop, fetchStartWithForward } from '../../../../redux/actions/common'
import {
  historyHead,
  shareholderHead,
  financialHead,
  corppassHead,
  capitalHead,
  appointmentHead,
} from './Step3'
import { dateFormatter } from '../SgVendorApplication'
import axios from 'axios'
import { getIntegrationURLFromENV } from '../../../../utils/getIntegrationURLFromENV'
import { CustomLoaderModal } from '../../../../_metronic/helpers/components/CustomLoaderModal'
import { AlexiRoutes } from '../../../../utils/constants/AlexiRoutes.enum'
import { EmailIcon } from '../../../../svg/EmailIcon'
import { MobileIcon } from '../../../../svg/MobileIcon'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'

const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// const dateObj = (d: any): any => {
//   return {
//     year: Number(d.getFullYear()),
//     month: Number(d.getMonth() + 1),
//     day: Number(d.getDate()),
//     hour: Number(d.getHours()),
//     minute: Number(d.getMinutes()),
//   }
// }

const Step4: FC = ({
  // prevStep,
  // nextStep,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const [openCustomLoaderModal, setOpenCustomLoaderModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [lastPickedDate, setLastPickedDate] = useState<any>(null)
  const [lastPickedDateError, setLastPickedDateError] = useState<boolean>(false)
  const [lastPickedTime, setLastPickedTime] = useState<any>(null)
  // const [initialDate, setInitialDate] = useState(dateObj(tomorrow))

  const decisionMaker = useSelector((state: any) => state.sgVendorsApplication.decisionMaker)
  const directors = useSelector((state: any) => state.sgVendorsApplication.directors)
  const loanApplication = useSelector((state: any) => state.sgVendorsApplication.partnerApplication)
  const loanEligibility = useSelector((state: any) => state.sgVendorsApplication.partnerEligibility)
  const singPassInfo = useSelector((state: any) => state.sgVendorsApplication.singPass)
  const partnerDetails = useSelector((state: any) => state.sgVendorsApplication.partner)

  const [createPhoneDiscussion, onSuccessCreatePhoneDiscussion] = useMutation(
    mutations.CREATE_PHONE_DISSCUSSION
  )
  const [resendGuarantorEmail, onSuccessResendGuarantorEmail] = useMutation(
    mutations.RESEND_GUARANTOR_EMAIL
  )

  const { phoneDiscussion, applicationStatus } = loanApplication

  // dateOfDiscussion: phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,
  // useEffect(() => {
  //   if (phoneDiscussion.length > 0) {
  //     const date = dateObj(new Date(phoneDiscussion[0].dateOfDiscussion))
  //     setInitialDate(date)
  //   }
  // }, [phoneDiscussion])

  useEffect(() => {
    if (onSuccessCreatePhoneDiscussion.called && !onSuccessCreatePhoneDiscussion.loading) {
      if (onSuccessCreatePhoneDiscussion.data) {
        updateApplication({
          variables: {
            data: { applicationCompleteStep: 5, applicationStatus: 'submitted' },
            where: { id: loanApplication.id },
          },
        })

        setTimeout(() => {
          history.push(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/completed/${loanApplication?.id}`)
        }, 1000)
      } else {
      }

      if (onSuccessCreatePhoneDiscussion.error) {
        const { networkError } = onSuccessCreatePhoneDiscussion.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessCreatePhoneDiscussion.loading])

  const editShareholders = () => {
    history.push(
      `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id}/${loanApplication.id}?step=4`
    )
  }

  useEffect(() => {
    if (onSuccessResendGuarantorEmail.called && !onSuccessResendGuarantorEmail.loading) {
      dispatch(fetchStop())
      if (onSuccessResendGuarantorEmail.data) {
        if (onSuccessResendGuarantorEmail.data.resendGuarantorEmail) {
          setShowAlert(true)
          setAlertType('success')
          setAlertMsg('Email sent successfully')
        } else {
          setShowAlert(true)
          setAlertType('primary')
          setAlertMsg('Email could not be sent')
        }
        onSuccessResendGuarantorEmail.reset()
      }

      if (onSuccessResendGuarantorEmail.error) {
        const { networkError } = onSuccessResendGuarantorEmail.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        dispatch(fetchStop())
      }
    }
  }, [onSuccessResendGuarantorEmail.loading])

  const initialValues = {
    applicantName:
      phoneDiscussion.length > 0 ? phoneDiscussion[0].applicantName : decisionMaker?.personName,
    applicantNumber:
      phoneDiscussion.length > 0
        ? phoneDiscussion[0].applicantNumber
        : decisionMaker?.corppasMobileNumber,
    dateOfDiscussion:
      phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,
    confirmCheckbox: false,
  }

  const validationSchema = Yup.object().shape({
    applicantName: Yup.string().required('Applicant Name required'),
    applicantNumber: Yup.string().required('Applicant Number required'),
    dateOfDiscussion: Yup.string()
      .required('Appointment Date and Time required')
      .typeError('Appointment Date and Time required'),
    confirmCheckbox: Yup.bool().oneOf([true], 'Please check checkbox to move forward'),
  })

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (lastPickedDateError) {
        formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
        return
      }

      const { applicantName, applicantNumber, dateOfDiscussion } = values
      dispatch(fetchStartWithForward())
      createPhoneDiscussion({
        variables: {
          data: {
            applicantName,
            applicantNumber,
            dateOfDiscussion,
            application: {
              connect: {
                id: loanApplication.id,
              },
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (lastPickedDateError) {
      formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
    }
  }, [formik.errors])

  const sendEmail = (id: string) => {
    dispatch(fetchStartWithForward())
    resendGuarantorEmail({
      variables: {
        shareholderId: id,
      },
    })
  }

  return (
    <div className='d-flex flex-column  flex-column-fluid'>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Shareholder Authentication Status</h2>
            <hr className='text-gray-400' />
          </div>
          <div className='sub-heading text-secondary-400 fs-4 mb-10'>
            Before submitting all shareholders mentioned below should complete their eKYC. A link
            has been sent to their email address.
          </div>
          <div className='row mb-10'>
            {directors &&
              directors.map((o: any, i: number) => {
                return (
                  !o.isGuarantor && (
                    <div className='col-md-6' key={i}>
                      <div className='cardNew w-100 border'>
                        <div className='d-flex gap-3 mb-5'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <span className='symbol-label bg-secondary text-inverse-secondary fw-bolder'>
                              {`${(o.personName.split(' ').length > 1
                                ? o.personName.split(' ')[0][0] +
                                  o.personName.split(' ')[o.personName.split(' ').length - 1][0]
                                : o.personName.split(' ')[0][0]
                              ).toUpperCase()}`}
                            </span>
                          </div>

                          {/* Person's Name and Director Role */}
                          <div>
                            <div className='fs-2 text-dark'>{o.personName}</div>
                            <div className='text-muted'>Director</div>
                          </div>

                          {/* KYC Status Badge */}
                          <div
                            className={`ms-auto badge ${
                              o.kycStatus === 'pending' ? 'badge-warning' : 'badge-success'
                            }`}
                          >
                            {o.kycStatus === 'pending' ? 'Pending' : 'Success'}
                          </div>
                        </div>

                        {/* Email and Mobile */}
                        <div
                          className='ms-13'
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div>
                            <div className='kyc_review fs-5 mt-1 mb-2 d-flex align-items-center'>
                              <EmailIcon color='currentColor' fontSize={18} />
                              <span className='ms-2'>{o.corppasEmail}</span>{' '}
                            </div>

                            <div className='kyc_review fs-5 mb-3 d-flex align-items-center'>
                              <MobileIcon color='currentColor' fontSize={18} />
                              <span className='ms-2'>{o.corppasMobileNumber}</span>{' '}
                            </div>
                          </div>
                          {/* Resend Button */}
                          {o.kycStatus === 'pending' && (
                            <div
                              className='button button-secondary-filled mb-4 '
                              onClick={(e) => sendEmail(o.id)}
                            >
                              Resend <RightAeroIcon color='currentColor' />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              })}
          </div>
          <div>
            {directors.length > 0 && directors.some((o: any) => o.kycStatus === 'pending') && (
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <div className='fs-6 text-gray-600'>
                    Thank you for providing us with the relevant information needed to complete the
                    company's loan application. You will be able to submit the application as soon
                    as the shareholders completes their eKYC.
                    <div className='text-black'>
                      Note: If shareholders have completed their eKYC then kindly refresh the page
                      to update the status
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Review Application</h2>
            <hr className='text-gray-400' />
          </div>
          <div className='sub-heading text-secondary-400 fs-4 mb-10'>
            Please review your application, this will be the last time you can review it before
            submitting it.{' '}
          </div>
          <div className='d-flex flex-column'>
            <Accordion defaultActiveKey='-1' className='custom-accordion'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header className='fs-2'>Credit Line Details</Accordion.Header>
                <Accordion.Body>
                  <div className='row'>
                    <div className='col-md-6 mb-3'>
                      <label className='input-field-label'>Credit Line Requested</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={`SG$ ${loanApplication?.amountRequested
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}`}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='input-field-label'>Company UEN</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={loanApplication?.companyUEN}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='input-field-label'>Position within company</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={loanApplication?.positionInCompany}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='input-field-label'>Applicant Name</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={loanApplication?.applicantName}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='input-field-label'>Mobile Number</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={loanApplication?.mobileNumber}
                        readOnly
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='1'>
                <Accordion.Header>Income Details</Accordion.Header>
                <Accordion.Body>
                  {loanApplication.incomeDocuments.length > 0 && (
                    <>
                      <h2 className='section-heading'>Bank Statements</h2>
                      <div
                        style={{
                          display: 'grid', // Change to grid layout
                          gridTemplateColumns: 'repeat(6, 1fr)', // Create 6 equal columns
                          gap: '12px', // Adjusted gap for better spacing
                          fontSize: '12px',
                          minWidth: '100%',
                        }}
                      >
                        {loanApplication.incomeDocuments.filter(
                          ({ type }: any) => type === 'bank-statement'
                        ).length > 0 ? (
                          <>
                            {loanApplication.incomeDocuments
                              .filter(({ type }: any) => type === 'bank-statement')
                              .map(({ documentFile }: any, i: number) => {
                                const filename = documentFile.filename.split('.')
                                // const name = filename[0].substring(0,15);
                                const name = filename[0]
                                const extension = filename[1]
                                return (
                                  <div
                                    key={i}
                                    style={{
                                      fontSize: 14,
                                      color: '#616B75',
                                      background: '#F9FAFA',
                                      borderRadius: 4,
                                      padding: '8px 16px',
                                      display: 'flex', // Use flex to position items
                                      justifyContent: 'space-between', // Space between text and icon
                                      alignItems: 'center', // Center items vertically
                                      width: '100%', // Set to 100% to fill the grid cell
                                    }}
                                  >
                                    {`${name}.${extension}`}
                                  </div>
                                )
                              })}
                          </>
                        ) : (
                          <div>No files uploaded</div>
                        )}
                      </div>

                      <h2 className='section-heading mt-10'>GST Statements</h2>
                      <div
                        style={{
                          display: 'grid', // Change to grid layout
                          gridTemplateColumns: 'repeat(6, 1fr)', // Create 6 equal columns
                          gap: '12px', // Adjusted gap for better spacing
                          fontSize: '12px',
                          minWidth: '100%',
                        }}
                      >
                        {loanApplication.incomeDocuments.filter(
                          ({ type }: any) => type === 'gst-statement'
                        ).length > 0 ? (
                          <>
                            {loanApplication.incomeDocuments
                              .filter(({ type }: any) => type === 'gst-statement')
                              .map(({ documentFile }: any, i: number) => {
                                const filename = documentFile.filename.split('.')
                                // const name = filename[0].substring(0,15);
                                const name = filename[0]
                                const extension = filename[1]
                                return (
                                  <div
                                    key={i}
                                    style={{
                                      fontSize: 14,
                                      color: '#616B75',
                                      background: '#F9FAFA',
                                      borderRadius: 4,
                                      padding: '8px 16px',
                                      display: 'flex', // Use flex to position items
                                      justifyContent: 'space-between', // Space between text and icon
                                      alignItems: 'center', // Center items vertically
                                      width: '100%', // Set to 100% to fill the grid cell
                                    }}
                                  >
                                    {`${name}.${extension}`}
                                  </div>
                                )
                              })}
                          </>
                        ) : (
                          <div className='fs-5'>No files uploaded</div>
                        )}
                      </div>
                      <h2 className='section-heading mt-10'>Approved Government Contracts</h2>
                      {loanApplication.incomeDocuments.filter(
                        ({ type }: any) => type === 'govt-contract'
                      ).length > 0 ? (
                        <>
                          {loanApplication.incomeDocuments
                            .filter(({ type }: any) => type === 'govt-contract')
                            .map(({ documentFile }: any, i: number) => {
                              const filename = documentFile.filename.split('.')
                              // const name = filename[0].substring(0,15);
                              const name = filename[0]
                              const extension = filename[1]
                              return (
                                <div
                                  key={i}
                                  style={{
                                    fontSize: 14,
                                    color: '#616B75',
                                    background: '#F9FAFA',
                                    borderRadius: 4,
                                    padding: '8px 16px',
                                    display: 'flex', // Use flex to position items
                                    justifyContent: 'space-between', // Space between text and icon
                                    alignItems: 'center', // Center items vertically
                                    width: '100%', // Set to 100% to fill the grid cell
                                  }}
                                >
                                  {`${name}.${extension}`}
                                </div>
                              )
                            })}
                        </>
                      ) : (
                        <div className='fs-5'>No files uploaded</div>
                      )}
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='2'>
                <Accordion.Header>Company Profile</Accordion.Header>
                <Accordion.Body>
                  <div className='section-heading'>
                    <h2>Company Profile</h2>
                    <hr className='text-gray-400' />
                  </div>
                  <div className='row'>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>UINFIN</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.uinfin || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Name</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.name || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Sex</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.sex || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Date of Birth</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.dob || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Mobile Number</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.mobileno || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Email</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.email || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Nationality</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.nationality || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Residential Status</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.residentialstatus || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Registered Address</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.regadd || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Owner Private</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.ownerprivate || 'N/A'}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className='section-heading mt-10'>
                    <h2>Notice of Assessment History</h2>
                    <hr className='text-gray-400' />
                  </div>

                  <div className='table-wrapper px-2'>
                    <table className='table table-bordered align-self-center w-100 px-2'>
                      <thead className='table-header p-2'>
                        <tr className='text-black'>
                          {singPassInfo.noahistory.length > 0 &&
                            [...historyHead, ...Object.keys(singPassInfo.noahistory[0])]
                              .reduce(
                                (acc: any, curr: any) =>
                                  acc.includes(curr) ? acc : [...acc, curr],
                                []
                              )
                              .map((key: any, i: number) => {
                                let tableHead = ''
                                if (key === 'taxclearance') tableHead = 'tax-clearance'
                                else if (key === 'yearofassessment')
                                  tableHead = 'year-of-assessment'
                                else tableHead = key

                                const value = tableHead
                                  .replace(/-/g, ' ')
                                  .replace(/(?: |\b)(\w)/g, (key: any, p1: any) =>
                                    key.toUpperCase()
                                  )
                                return (
                                  <th className='min-w-150px' key={i}>
                                    {value}
                                  </th>
                                )
                              })}
                        </tr>
                      </thead>
                      <tbody>
                        {singPassInfo.noahistory.length > 0 &&
                          Object.values(singPassInfo.noahistory).map((list: any, i) => {
                            const headList: any = [
                              ...historyHead,
                              ...Object.keys(singPassInfo.noahistory[0]),
                            ].reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            return (
                              <tr key={i}>
                                {headList.map((head: any, i: number) => {
                                  let value = `${list[head].value}`

                                  if (
                                    head === 'amount' ||
                                    head === 'interest' ||
                                    head === 'yearofassessment' ||
                                    head === 'employment'
                                  )
                                    value = value
                                      .toString()
                                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')
                                  if (head === 'amount' || head === 'interest')
                                    value = 'SG$ ' + value
                                  else value = value.toString()
                                  return <td key={i}>{value}</td>
                                })}
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>

                  <div className='section-heading mt-10'>
                    <h2>Corporate Information</h2>
                    <hr className='text-gray-400' />
                  </div>
                  <div className='row'>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>UEN</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.uen || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Entity Name</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.entityName || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Entity Type</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.entityType || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Entity Status</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.entityStatus || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Registered Address</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.enregadd || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Registered Date</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.registrationDate || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Primary SSIC</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.primarySSIC || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Secondary SSIC</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.secondarySSIC || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Business Expiry Date</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.businessExpiryDate || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Country Of Incorporation</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.countryOfIncorporation || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Business Constitution</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.businessConstitution || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='input-field-label'>Ownership</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.ownership || 'N/A'}
                        readOnly
                      />
                    </div>
                    <div className='col-md-12 mb-5'>
                      <label className='input-field-label'>Company Type</label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={singPassInfo?.companyType || 'N/A'}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className='section-heading mt-10'>
                    <h2>Financials</h2>
                    <hr className='text-gray-400' />
                  </div>
                  <div className='table-wrapper px-2'>
                    <table className='table table-bordered align-self-center w-100 px-2'>
                      <thead className='table-header p-2'>
                        <tr className='text-black ms-2'>
                          {singPassInfo.financials.length > 0 &&
                            [...financialHead, ...Object.keys(singPassInfo.financials[0])]
                              .reduce(
                                (acc: any, curr: any) =>
                                  acc.includes(curr) ? acc : [...acc, curr],
                                []
                              )
                              .map((tableHead: any, i: number) => {
                                const value = tableHead
                                  .replace(/-/g, ' ')
                                  .replace(/(?: |\b)(\w)/g, (key: any, p1: any) =>
                                    key.toUpperCase()
                                  )
                                return (
                                  <th
                                    key={i}
                                    className={i === 0 ? ' min-w-150px ps-2' : 'min-w-150px'}
                                  >
                                    {value}
                                  </th>
                                )
                              })}
                        </tr>
                      </thead>
                      <tbody>
                        {singPassInfo.financials.length > 0 &&
                          Object.values(singPassInfo.financials).map((list: any, i) => {
                            const headList: any = [
                              ...financialHead,
                              ...Object.keys(singPassInfo.financials[0]),
                            ].reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            return (
                              <tr key={i}>
                                {headList.map((head: any, i: number) => {
                                  let value: any
                                  if (head === 'currency') value = `${list?.[head]?.desc ?? 'N/A'}`
                                  else if (
                                    head === 'current-period-end-date' ||
                                    head === 'current-period-start-date'
                                  )
                                    value = `${list?.[head]?.value ?? 'N/A'}`
                                  else
                                    value = `${
                                      list?.[head]?.value
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 'N/A'
                                    }`
                                  return (
                                    <td key={i} className=' ps-2'>
                                      {value || 'N/A'}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>

                    {singPassInfo.financials.length === 0 && (
                      <table className='table table-bordered align-self-center w-100 px-2'>
                        <thead className='table-header p-2'>
                          <tr className='text-black'>
                            {financialHead.map((tableHead: any, i: number) => {
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px ps-2' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {financialHead.map((key: string, i: number) => {
                              return (
                                <Fragment key={i}>
                                  <td>N/A</td>
                                </Fragment>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className='section-heading mt-10'>
                    <h2>Corppass Contacts</h2>
                    <hr className='text-gray-400' />
                  </div>
                  <div className='table-wrapper px-2'>
                    <table className='table table-bordered align-self-center w-100 px-2'>
                      <thead className='table-header p-2'>
                        <tr className='text-black ms-2 '>
                          {singPassInfo.corppassContacts &&
                            singPassInfo.corppassContacts.length > 0 &&
                            [...corppassHead, ...Object.keys(singPassInfo.corppassContacts[0])]
                              .reduce(
                                (acc: any, curr: any) =>
                                  acc.includes(curr) ? acc : [...acc, curr],
                                []
                              )
                              .map((key: any, i: number) => {
                                let tableHead = ''
                                if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                                else tableHead = key
                                const value = tableHead
                                  .replace(/-/g, ' ')
                                  .replace(/(?: |\b)(\w)/g, (key: any, p1: any) =>
                                    key.toUpperCase()
                                  )
                                return (
                                  <th className='min-w-150px ps-2' key={i}>
                                    {value}
                                  </th>
                                )
                              })}
                        </tr>
                      </thead>
                      <tbody>
                        {singPassInfo.corppassContacts &&
                          singPassInfo.corppassContacts.length > 0 &&
                          Object.values(singPassInfo.corppassContacts).map((list: any, i) => {
                            const headList: any = [
                              ...corppassHead,
                              ...Object.keys(singPassInfo.corppassContacts[0]),
                            ].reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            return (
                              <tr key={i}>
                                {headList.map((head: any, i: number) => {
                                  let value: any
                                  if (head === 'person-reference') {
                                    value = (
                                      <div>
                                        <div>
                                          <span className='fw-bold'>IdNo: </span>
                                          {list?.[head]?.idno?.value ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>Nationality: </span>
                                          {list?.[head]?.nationality?.desc ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>Person name: </span>
                                          {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                        </div>
                                      </div>
                                    )
                                  } else if (head === 'corppass-email')
                                    value = `${list?.[head]?.value ?? 'N/A'}`
                                  else if (head === 'corppass-mobileno')
                                    value = `${list?.[head]?.value ?? 'N/A'}`
                                  return <td key={i}>{value || 'N/A'}</td>
                                })}
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>

                    {singPassInfo.corppassContacts.length === 0 && (
                      <table className='table table-bordered align-self-center w-100 px-2'>
                        <thead className='table-header p-2'>
                          <tr className='text-black ms-2 '>
                            {corppassHead.map((key: any, i: number) => {
                              let tableHead = ''
                              if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                              else tableHead = key
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px ps-2' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {corppassHead.map((key: string, i: number) => {
                              return (
                                <Fragment key={i}>
                                  <td className='ps-2'>N/A</td>
                                </Fragment>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className='section-heading mt-10'>
                    <h2>Capitals</h2>
                    <hr className='text-gray-400' />
                  </div>
                  <div className='table-wrapper px-2'>
                    <table className='table table-bordered align-self-center w-100 px-2'>
                      <thead className='table-header p-2'>
                        <tr className='text-black ms-2 ps-2'>
                          {singPassInfo.capitals &&
                            singPassInfo.capitals.length > 0 &&
                            [...capitalHead, ...Object.keys(singPassInfo.capitals[0])]
                              .reduce(
                                (acc: any, curr: any) =>
                                  acc.includes(curr) ? acc : [...acc, curr],
                                []
                              )
                              .map((tableHead: any, i: number) => {
                                const value = tableHead
                                  .replace(/-/g, ' ')
                                  .replace(/(?: |\b)(\w)/g, (key: any, p1: any) =>
                                    key.toUpperCase()
                                  )
                                return (
                                  <th className='min-w-150px ps-2' key={i}>
                                    {value}
                                  </th>
                                )
                              })}
                        </tr>
                      </thead>
                      <tbody>
                        {singPassInfo.capitals &&
                          singPassInfo.capitals.length > 0 &&
                          Object.values(singPassInfo.capitals).map((list: any, i) => {
                            const headList: any = [
                              ...capitalHead,
                              ...Object.keys(singPassInfo.capitals[0]),
                            ].reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            return (
                              <tr key={i}>
                                {headList.map((head: any, i: number) => {
                                  let value: any
                                  if (
                                    head === 'capital-type' ||
                                    head === 'currency' ||
                                    head === 'currency'
                                  )
                                    value = `${list?.[head]?.desc ?? 'N/A'}`
                                  else if (
                                    head === 'issued-capital-amount' ||
                                    head === 'paid-up-capital-amount' ||
                                    head === 'share-allotted-amount'
                                  )
                                    value = `SG$ ${
                                      list?.[head]?.value
                                        .toString()
                                        .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,') ?? 'N/A'
                                    }`
                                  return (
                                    <td key={i} className='ps-2'>
                                      {value || 'N/A'}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>

                    {singPassInfo.capitals.length === 0 && (
                      <table className='table table-bordered align-self-center w-100 px-2'>
                        <thead className='table-header p-2'>
                          <tr className='text-black ms-2 ps-2'>
                            {capitalHead.map((tableHead: any, i: number) => {
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px ps-2' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {capitalHead.map((key: string, i: number) => {
                              return (
                                <Fragment key={i}>
                                  <td className='ps-2'>N/A</td>
                                </Fragment>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className='section-heading mt-10'>
                    <h2>Shareholders List</h2>
                    <hr className='text-gray-400' />
                  </div>
                  <div className='table-wrapper px-2'>
                    <table className='table table-bordered align-self-center w-100 px-2'>
                      <thead className='table-header p-2'>
                        <tr className='text-black ms-2 '>
                          {singPassInfo.shareholdersList &&
                            singPassInfo.shareholdersList.length > 0 &&
                            [...shareholderHead, ...Object.keys(singPassInfo.shareholdersList[0])]
                              .reduce(
                                (acc: any, curr: any) =>
                                  acc.includes(curr) ? acc : [...acc, curr],
                                []
                              )
                              .map((key: any, i: number) => {
                                let tableHead = ''
                                if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                                else tableHead = key
                                const value = tableHead
                                  .replace(/-/g, ' ')
                                  .replace(/(?: |\b)(\w)/g, (key: any, p1: any) =>
                                    key.toUpperCase()
                                  )
                                return (
                                  <th className='min-w-150px ps-2' key={i}>
                                    {value}
                                  </th>
                                )
                              })}
                        </tr>
                      </thead>
                      <tbody>
                        {singPassInfo.shareholdersList &&
                          singPassInfo.shareholdersList.length > 0 &&
                          Object.values(singPassInfo.shareholdersList).map((list: any, i) => {
                            const headList: any = [
                              ...shareholderHead,
                              ...Object.keys(singPassInfo.shareholdersList[0]),
                            ].reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            return (
                              <tr key={i}>
                                {headList.map((head: any, i: number) => {
                                  let value: any
                                  // if(head === 'person-reference') value = `IdNo: ${list[head].idno.value} Nationality: ${list[head].nationality.desc} Person name: ${list[head]['person-name'].value}`
                                  if (head === 'person-reference') {
                                    value = (
                                      <div className='ps-2'>
                                        <div>
                                          <span className='fw-bold'>IdNo: </span>
                                          {list?.[head]?.idno?.value ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>Nationality: </span>
                                          {list?.[head]?.nationality?.desc ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>Person name: </span>
                                          {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                        </div>
                                      </div>
                                    )
                                  } else if (head === 'entity-reference') {
                                    value = (
                                      <div className='ps-2'>
                                        <div>
                                          <span className='fw-bold'>Name: </span>
                                          {list?.[head]?.['entity-name']?.value ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>Type: </span>
                                          {list?.[head]?.['entity-type']?.desc ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>UEN: </span>
                                          {list?.[head]?.uen?.value ?? 'N/A'}
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    head === 'category' ||
                                    head === 'currency' ||
                                    head === 'share-type'
                                  )
                                    value = `${list?.[head]?.desc ?? 'N/A'}`
                                  else if (head === 'allocation')
                                    value = `SG$  ${list?.[head]?.value
                                      .toString()
                                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}`
                                  else if (
                                    head === 'corppass-email' ||
                                    head === 'corppass-mobileno'
                                  )
                                    value = `${list?.[head]?.value ?? 'N/A'}`
                                  return (
                                    <td key={i} className='ps-2'>
                                      {value}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>

                    {singPassInfo.shareholdersList.length === 0 && (
                      <table className='table table-bordered align-self-center w-100 px-2'>
                        <thead className='table-header p-2'>
                          <tr className='text-black ms-2 ps-2'>
                            {shareholderHead.map((key: any, i: number) => {
                              let tableHead = ''
                              if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                              else tableHead = key
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px ps-2' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {shareholderHead.map((key: string, i: number) => {
                              return (
                                <Fragment key={i}>
                                  <td className='ps-2'>N/A</td>
                                </Fragment>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className='section-heading mt-10'>
                    <h2>Appointments</h2>
                    <hr className='text-gray-400' />
                  </div>
                  <div className='table-wrapper px-2'>
                    <table className='table table-bordered align-self-center w-100 px-2'>
                      <thead className='table-header p-2'>
                        <tr className='text-black ms-2'>
                          {singPassInfo?.appointments &&
                            singPassInfo?.appointments?.length > 0 &&
                            [...appointmentHead, ...Object.keys(singPassInfo.appointments[0])]
                              .reduce(
                                (acc: any, curr: any) =>
                                  acc.includes(curr) ? acc : [...acc, curr],
                                []
                              )
                              .map((key: any, i: number) => {
                                let tableHead = ''
                                if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                                else tableHead = key
                                const value = tableHead
                                  .replace(/-/g, ' ')
                                  .replace(/(?: |\b)(\w)/g, (key: any, p1: any) =>
                                    key.toUpperCase()
                                  )
                                return (
                                  <th className='min-w-150px  ps-2' key={i}>
                                    {value}
                                  </th>
                                )
                              })}
                        </tr>
                      </thead>
                      <tbody>
                        {singPassInfo.appointments &&
                          singPassInfo.appointments.length > 0 &&
                          Object.values(singPassInfo.appointments).map((list: any, i) => {
                            const headList: any = [
                              ...appointmentHead,
                              ...Object.keys(singPassInfo.appointments[0]),
                            ].reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )

                            return (
                              <tr key={i}>
                                {headList.map((head: any, i: number) => {
                                  let value: any
                                  // if(head === 'person-reference') value = `IdNo: ${list[head].idno.value} Nationality: ${list[head].nationality.desc} Person name: ${list[head]['person-name'].value}`
                                  if (head === 'person-reference') {
                                    value = (
                                      <div className='ps-2'>
                                        <div>
                                          <span className='fw-bold'>IdNo: </span>
                                          {list?.[head]?.idno?.value ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>Nationality: </span>
                                          {list?.[head]?.nationality?.desc ?? 'N/A'}
                                        </div>
                                        <div>
                                          <span className='fw-bold'>Person name: </span>
                                          {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                        </div>
                                      </div>
                                    )
                                  } else if (head === 'position')
                                    value = `${list?.[head]?.desc ?? 'N/A'}`
                                  else if (head === 'appointment-date')
                                    value = `${list?.[head]?.value ?? 'N/A'}`
                                  else if (head === 'corppass-email')
                                    value = `${list?.[head]?.value ?? 'N/A'}`
                                  else if (head === 'category')
                                    value = `${list?.[head]?.desc ?? 'N/A'}`
                                  else if (head === 'corppass-mobileno')
                                    value = `${list?.[head]?.value ?? 'N/A'}`
                                  else value = ''
                                  return (
                                    <td key={i} className='ps-2'>
                                      {value || 'N/A'}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>

                    {singPassInfo.appointments.length === 0 && (
                      <table className='table table-bordered align-self-center w-100 px-2'>
                        <thead className='table-header p-2'>
                          <tr className='text-black ms-2 ps-2'>
                            {appointmentHead.map((key: any, i: number) => {
                              let tableHead = ''
                              if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                              else tableHead = key
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px ps-2' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {appointmentHead.map((key: string, i: number) => {
                              return (
                                <Fragment key={i}>
                                  <td className='ps-2'>N/A</td>
                                </Fragment>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='3'>
                <Accordion.Header className='fs-2'>Shareholders</Accordion.Header>
                <Accordion.Body>
                  {loanApplication.shareholderKycStatus.length > 0 && (
                    <>
                      <div className='table-shareholder-wrapper'>
                        <table className='table table-bordered align-self-center w-100 px-2'>
                          <thead className='table-header px-2'>
                            <tr className='text-dark'>
                              <th className='min-w-150px ms-2 ps-3'>Name</th>
                              <th className='min-w-140px'>Allocation</th>
                              <th className='min-w-120px'>Decision Maker</th>
                              <th className='min-w-120px'>Director</th>
                              <th className='min-w-120px'>Email Address</th>
                              <th className='min-w-120px'>Mobile No</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[...loanApplication.shareholderKycStatus]
                              .sort(({ allocation: a }: any, { allocation: b }: any) => b - a)
                              .map((o: any, i: number) => {
                                return (
                                  <tr key={i}>
                                    <td className='ms-2 ps-3'>{o.personName}</td>
                                    <td>{o.allocation.toFixed(2)}%</td>
                                    <td>
                                      {o.isDecisionMaker && (
                                        <input
                                          type='checkbox'
                                          className='form-check-input custom-checkbox'
                                          defaultChecked={o.isDecisionMaker}
                                          disabled
                                        />
                                      )}
                                    </td>

                                    <td>
                                      {o.isDirector && (
                                        <input
                                          type='checkbox'
                                          className='form-check-input custom-checkbox'
                                          defaultChecked={o.isDirector}
                                          disabled
                                        />
                                      )}
                                    </td>
                                    <td>{o.corppasEmail}</td>
                                    <td>{o.corppasMobileNumber}</td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        {applicationStatus === 'in-progress' &&
          directors.length > 0 &&
          !directors.some((o: any) => o.kycStatus === 'pending') && (
            <>
              <div className='cardNew'>
                <div className='section-heading'>
                  <h2>Consent and Terms</h2>
                  <hr className='text-gray-400' />
                </div>
                <div className='justify-content'>
                  <p className='fs-5'>You are about to submit your application</p>
                  <p className='fs-5'>
                    <label className='form-check-label me-1 mt-2 d-inline-flex align-items-center'>
                      <input
                        name='confirmCheckbox'
                        type='checkbox'
                        className='form-check-input form-check-inline form-check-solid'
                        checked={formik.values.confirmCheckbox}
                        onChange={formik.handleChange}
                      />
                      <span className='fs-5'>
                        By clicking this I agree that all the above information is correct and can
                        be used for underwriting. Please find Terms and Conditions
                        <a
                          href={toAbsoluteUrl('/files/Standard_Customer_T&Cs.pdf')}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='ms-2'
                        >
                          Here
                        </a>
                      </span>
                    </label>
                    {formik.touched.confirmCheckbox && formik.errors.confirmCheckbox && (
                      <div className='text-danger mt-2'>{formik.errors.confirmCheckbox}</div>
                    )}
                  </p>
                </div>
              </div>
            </>
          )}

        <div className='cardNew stickeyFooter'>
          <div className='d-flex flex-end'>
            <button
              type='submit'
              className='button  button-primary me-3'
              disabled={
                applicationStatus === 'submitted' ||
                directors.some((o: any) => o.kycStatus === 'pending')
              }
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}

      <CustomLoaderModal
        openCustomLoaderModal={openCustomLoaderModal}
        setOpenCustomLoaderModal={setOpenCustomLoaderModal}
        message='Please wait while we process your document for signing, it might take a while.'
      />
    </div>
  )
}

export { Step4 }
