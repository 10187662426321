import React, { FunctionComponent } from 'react'
import { Stack } from 'react-bootstrap'
import { ArrowClockwise, ArrowRight, XCircleFill } from 'react-bootstrap-icons'
import { Card, Tooltip } from 'react-bootstrap-v5'
import { Xicon } from '../../../svg/Xicon'
interface FailedBankStatementProps {
  file: File & { key: string }
  status?: number
  error?: string
  onClose: () => void
}

const FailedBankStatement: FunctionComponent<FailedBankStatementProps> = ({
  file,
  error,
  status,
  onClose,
}) => {
  const uploadStatus = status == 200 ? 'success' : 'error'

  const getIcon = () => {
    if (uploadStatus === 'error') {
      return <ArrowClockwise color='var(--error-red)' size={16} />
    }

    return <Card />
  }

  const getCancelIcon = () => {
    if (status != 200 || error) {
      return <Xicon onClick={onClose} style={{ cursor: 'pointer' }} />
    }
  }
  return (
    <>
      <Stack
        style={{
          width: 'auto',
          display: 'inline-block',
          marginBottom: '10px',
          marginRight: '10px',
          verticalAlign: 'top',
        }}
      >
        <Stack
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px', // Adjusted gap for better spacing
            color: '#616B75',
            background: '#F9FAFA',
            borderRadius: 4,
            padding: '8px 16px',
            fontSize: '12px',
            minWidth: '100%',
            display: 'flex', // Changed to 'flex' for better alignment
          }}
        >
          {getIcon()}
          <div
            style={{
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1, // Allow this div to grow and take available space
            }}
          >
            {file?.name}
          </div>
          {getCancelIcon()}
        </Stack>

        {error && (
          <div className='text-danger' style={{ fontSize: 14, marginTop: '4px' }}>
            <span style={{ fontSize: 14 }}>*</span>
            {error}
          </div>
        )}
      </Stack>
    </>
  )
}

export default FailedBankStatement
