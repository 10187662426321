import { SVGProps } from 'react'

export function BankIcon({
  color = 'var(--primary-color)',
  fontSize = '33',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 16 33'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <path d='M16 32.3037L0 32.3574L4 12.6787H12.5714L16 32.3037Z' fill='#E84680' />
      <path
        d='M4 4.53571C4 2.16878 5.91878 0.25 8.28572 0.25C10.6526 0.25 12.5714 2.16878 12.5714 4.53571C12.5714 6.90265 10.6526 8.82143 8.28572 8.82143C5.91878 8.82143 4 6.90265 4 4.53571Z'
        fill='#E84680'
      />
    </svg>
  )
}
