import { SVGProps } from 'react'

export function LoanError({
  color = 'var(--primary-color)',
  fontSize = '60',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={fontSize}
        height={fontSize}
        style={style}
        viewBox='0 0 58 58'
        fill='none'
      >
        <path
          d='M29.0009 57.9828C13.1207 57.9828 0.200928 45.063 0.200928 29.1828C0.200928 13.3026 13.1207 0.382812 29.0009 0.382812C44.8811 0.382812 57.8009 13.3026 57.8009 29.1828C57.8009 45.063 44.8811 57.9828 29.0009 57.9828ZM29.0009 3.98281C15.1055 3.98281 3.80093 15.2874 3.80093 29.1828C3.80093 43.0782 15.1055 54.3828 29.0009 54.3828C42.8963 54.3828 54.2009 43.0782 54.2009 29.1828C54.2009 15.2874 42.8963 3.98281 29.0009 3.98281ZM42.4061 41.5932C42.6176 41.4875 42.8061 41.3412 42.961 41.1626C43.1159 40.9841 43.2342 40.7767 43.3089 40.5525C43.3837 40.3282 43.4136 40.0914 43.3968 39.8556C43.3801 39.6198 43.317 39.3897 43.2113 39.1782C43.0433 38.8428 38.9987 30.9828 29.0009 30.9828C19.0031 30.9828 14.9585 38.8428 14.7905 39.1776C14.5781 39.6029 14.5429 40.0951 14.6927 40.5464C14.8425 40.9976 15.1651 41.371 15.5897 41.5848C16.0152 41.7987 16.5079 41.8359 16.9606 41.6881C17.4133 41.5403 17.7893 41.2196 18.0065 40.7958C18.1367 40.542 21.2789 34.5828 29.0009 34.5828C36.7229 34.5828 39.8651 40.542 39.9905 40.788C40.14 41.0875 40.37 41.3394 40.6549 41.5151C40.9397 41.6909 41.268 41.7836 41.6027 41.7828C41.8727 41.7828 42.1475 41.7222 42.4061 41.5932ZM38.0009 25.5828C36.0155 25.5828 34.4009 23.9682 34.4009 21.9828C34.4009 19.9974 36.0155 18.3828 38.0009 18.3828C39.9863 18.3828 41.6009 19.9974 41.6009 21.9828C41.6009 23.9682 39.9863 25.5828 38.0009 25.5828ZM20.0009 25.5828C18.0155 25.5828 16.4009 23.9682 16.4009 21.9828C16.4009 19.9974 18.0155 18.3828 20.0009 18.3828C21.9863 18.3828 23.6009 19.9974 23.6009 21.9828C23.6009 23.9682 21.9863 25.5828 20.0009 25.5828Z'
          fill='url(#paint0_linear_28462_10851)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_28462_10851'
            x1='-13.3419'
            y1='29.1828'
            x2='57.6739'
            y2='32.1858'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='var(--gradient-color-blue)' />
            <stop offset='0.328448' stop-color='var(--gradient-color-purple)' />
            <stop offset='0.635057' stop-color='var(--gradient-color-pink)' />
            <stop offset='1' stop-color='var(--gradient-color-orange)' />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}
