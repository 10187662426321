import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap-v5'
import axios from 'axios'
//const type = parse(history.location.search).type as string;

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { setCompanyVerificationMethod, setEntityDataPartner, setPartnerStep } from './redux/actions'
import { AlexiRoutes } from '../../../utils/constants/AlexiRoutes.enum'
import { AlexiApplicationTypes } from '../../../utils/constants/AlexiApplicationType.enum'
import { setEntityData } from '../loanApplication/redux/actions'
import { CompanyProfile } from '../../../svg/CompanyProfileIcon'
import { BankIcon } from '../../../svg/BankIIcon'
import { TileIcon } from '../../../svg/TileIcon'

interface Props {
  show: boolean
  handleClose: any
  // goToStep:any;
}

const CompanyVerification: FC<Props> = ({
  show,
  handleClose,
}: // goToStep
any) => {
  const dispatch = useDispatch()
  const history: any = useHistory()
  const loanApplicationId = localStorage.getItem('loanApplicationId')
  const loanType = localStorage.getItem('loanType')
  const partnerId = localStorage.getItem('partnerId')

  const loanApplication = useSelector((state: any) => state.sgVendorsApplication.partnerApplication)
  const companyVerificationMethod = useSelector(
    (state: any) => state.sgVendorsApplication.companyVerificationMethod
  )
  const partnerDetails = useSelector((state: any) => state.sgVendorsApplication.partner)

  const selectCompanyVerificationMethod = (method: number) => {
    dispatch(setCompanyVerificationMethod(method))
  }

  const connectMyInfo = async () => {
    localStorage.setItem('loanApplicattionId', loanApplication?.id)
    localStorage.setItem('singpass', 'myinfobiz')

    // const singPassUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://iris-dev.credilinq.ai/api/alexi-singpass';
    const singPassUrl = process.env.REACT_APP_SINGPASS_URL || 'http://localhost:3002'

    const getEnv = await axios.get(singPassUrl + '/getEnv').then((response) => response.data)

    const state = Math.floor(Math.random() * 100000)
    const {
      clientId,
      redirectUrl,
      attributes,
      purpose,
      environment,
      authApiUrl,
      myInfoAuthApiUrl,
      myInfoBizAuthApiUrl,
    } = getEnv
    const authoriseUrl: any = `${authApiUrl}?client_id=${clientId}&attributes=${attributes}&purpose=${purpose}&state=${encodeURIComponent(
      state
    )}&redirect_uri=${redirectUrl}`
    ;(window as any).location = authoriseUrl
  }

  const submitProfileVerification = () => {
    if (companyVerificationMethod === 1) {
      connectMyInfo()
    } else {
      // goToStep(3);
      dispatch(setPartnerStep(3))

      if (
        loanApplicationId &&
        ['stage', 'sandbox', 'dev'].includes(localStorage.getItem('env') as string)
      ) {
        ;(async () => {
          const response = await import(
            `../../../utils/custom/${
              loanApplication.companyUEN ? loanApplication.companyUEN : '2012011013R'
            }/step3.json`
          )
          const eligibilityData: any = response.default
          if (eligibilityData) {
            // Dispatch the action to update the loan eligibility in the Redux store
            dispatch(setEntityDataPartner(eligibilityData))
          }
        })()

        history.replace(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplicationId}?step=3`)
      } else {
        history.replace(
          `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id}/${loanApplication.id}?step=3`
        )
      }
    }
    handleClose()
  }

  return (
    <>
      <Modal
        className='bg-transparent'
        id='kt_mega_menu_modal'
        data-backdrop='static'
        aria-hidden='true'
        role='dialog'
        tabIndex='-1'
        contentClassName='shadow-none'
        show={show}
        onHide={handleClose}
        centered // Bootstrap's built-in class for centering modals
      >
        <div className='modal-content w-100 px-8'>
          <div className='d-flex flex-column align-items-center justify-content-center pt-6'>
            <CompanyProfile className='mb-4' />
            <h2 className='section-heading m-0 mt-4'>Verify Company Profile</h2>
            <p className='text-muted mb-6'>
              Please select how you would like to verify your company profile
            </p>
          </div>

          <div className='d-block mx-5 my-5'>
            <div className='d-flex align-items-center min-h-145px'>
              <div className='fv-row fv-plugins-icon-container'>
                <div className='row'>
                  <div className='col-lg-12 loan-application '>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10 verify-profile ${
                        companyVerificationMethod === 1 ? 'verify-profile-click' : ''
                      }`}
                      htmlFor='kt_create_account_form_account_type_personal'
                      onClick={() => selectCompanyVerificationMethod(1)}
                    >
                      {/* <KTSVG
                      path='/media/icons/duotune/communication/com005.svg'
                      className='svg-icon-3x me-5'
                    /> */}

                      {/* <span className='text-dark fw-bolder d-block fs-4 mb-2'>MyInfo Business with Singpass</span> */}
                      <BankIcon />
                      <span className='d-block fw-bold text-start ms-4'>
                        <span className='text-primary fw-bold d-block fs-2 mb-2'>
                          MyInfo Business with SingPass
                        </span>
                        <span className='text-color-10  fs-6'>Takes about 1-2 minutes</span>
                      </span>
                    </label>
                  </div>

                  <div className='col-lg-12 loan-application '>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center verify-profile-manual ${
                        companyVerificationMethod === 2 ? 'selected_manual' : ''
                      }`}
                      htmlFor='kt_create_account_form_account_type_corporate'
                      onClick={() => selectCompanyVerificationMethod(2)}
                    >
                      <TileIcon />

                      <span className='d-block fw-bold text-start ms-4'>
                        <span className='text-dark fw-bold d-block fs-2 mb-2'>
                          Fill out details Manually
                        </span>
                        <span className='text-gray-400 fs-6'>Takes about 5-10 minutes</span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column gap-5 flex-center mx-5 my-10'>
            <button
              className='button button-primary  w-100'
              onClick={() => submitProfileVerification()}
            >
              Verify
            </button>
            <div className='line d-flex justify-content-center text-muted'>
              <span className='zIndex-1'>OR</span>
            </div>
            <button className='button button-outline-back w-100' onClick={() => handleClose()}>
              Back
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CompanyVerification
