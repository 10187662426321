import { SVGProps } from 'react'

export function LeftArowIcon({
  color = 'var(--text-color-2)',
  fontSize = '16',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 20 20'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <path
        d='M16.5 9C17.0523 9 17.5 8.55228 17.5 8C17.5 7.44772 17.0523 7 16.5 7V9ZM0.792892 7.29289C0.402369 7.68342 0.402369 8.31658 0.792892 8.70711L7.15685 15.0711C7.54738 15.4616 8.18054 15.4616 8.57107 15.0711C8.96159 14.6805 8.96159 14.0474 8.57107 13.6569L2.91421 8L8.57107 2.34315C8.96159 1.95262 8.96159 1.31946 8.57107 0.928932C8.18054 0.538408 7.54738 0.538408 7.15685 0.928932L0.792892 7.29289ZM16.5 7L1.5 7V9L16.5 9V7Z'
        fill={color}
      />
    </svg>
  )
}
