export const MaintenanceModeBanner = () => {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE || 'false'

  return (
    <>
      {isMaintenanceMode === 'true' ? (
        <div
          className='mb-10 mt-10'
          style={{
            backgroundColor: '#fdcb6e',
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid gray',
            borderRadius: '5px',
            flexDirection: 'column',
          }}
        >
          <div style={{ fontSize: '18px', fontWeight: '900px' }}>Alexi is under maintenance!</div>
          <div style={{ fontSize: '14px', fontWeight: '900px' }}>
            please contact the{' '}
            <a href='mailto:loans@credilinq.ai' className=''>
              support team
            </a>{' '}
            in case any assistance is required
          </div>
        </div>
      ) : null}
    </>
  )
}
