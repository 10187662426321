import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { fetchStart } from '../../../../redux/actions/common'
import { setSubStep } from '../redux/actions'
import { trim } from '../../../../_metronic/helpers'

const errorMsg = {
  required: 'Required',
  amountRange: 'Amount range should be between 75,000 - 250,000',
  durationRange: 'Duration range should be between 3 - 12 months',
}

const Step1Sub1: FC<any> = ({ sendOTP, setMobile, id, name, mobile }) => {
  const dispatch = useDispatch()

  const initialValues = {
    applicantName: name ? name : '',
    mobileNumber: mobile ? mobile : '',
  }

  const validationSchema = Yup.object().shape({
    applicantName: Yup.string()
      .required('Applicant Name is required')
      .test('unique', 'Applicant Name must not contain numbers', (value: any) => {
        return !/\d/.test(value)
      }),
    mobileNumber: Yup.string()
      .required(errorMsg.required)
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      })
      .test(
        'mobileValidation',
        'Invalid Mobile number. Kindly recheck the entered number',
        () => true
      ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const { mobileNumber } = trim(values)

      dispatch(fetchStart())
      setMobile(mobileNumber)
      sendOTP({
        variables: {
          mobileNo: mobileNumber,
          isApplicantOrShareholder: false,
          applicantId: id,
        },
      })

      setSubmitting(false)
    },
  })

  return (
    <div className='d-flex flex-column '>
      <div className='notice d-flex banner text-start p-6 mb-5'>
        <div className='d-flex flex-stack flex-grow-1'>
          <div>
            <h2>Co-Applicant eKYC</h2>
            <div className='fs-3'>
              You have been added as a guarantor or director for your company. Kindly proceed by
              completing your eKYC via Myinfo Singpass
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Applicant Details</h2>
            <hr className='text-gray-400' />
          </div>

          <div className='row'>
            <div className='col-md-6 mb-4'>
              <label htmlFor='' className='input-field-label required'>
                Applicant Name
              </label>
              <div className='col-sm-12'>
                <input
                  name='applicantName'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.applicantName}
                  onChange={formik.handleChange}
                />
                {formik.touched.applicantName && formik.errors.applicantName && (
                  <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
                )}
              </div>
            </div>

            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Mobile Number
              </label>
              <div className='col-sm-12'>
                <PhoneInput
                  id='mobileNumber'
                  international
                  defaultCountry='SG'
                  // countryCallingCodeEditable={false}
                  value={formik.values.mobileNumber}
                  onChange={(value) => formik.setFieldValue('mobileNumber', value)}
                  placeholder='Enter phone number'
                  className='int-phone-input'
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className='text-danger mt-2'>{formik.errors.mobileNumber}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='cardNew stickeyFooter'>
          <div className='d-flex flex-end'>
            <button type='submit' className='button button-primary'>
              Send OTP
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export { Step1Sub1 }
