/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ServerError, useMutation } from '@apollo/client'
import { parse } from 'query-string'

import { Step1Sub0 } from './Step1Sub0'
import { Step1Sub1 } from './Step1Sub1'
import { Step1Sub2 } from './Step1Sub2'
import { Step1Sub3 } from './Step1Sub3'
import { Step1Sub4 } from './Step1Sub4'
import Alert from '../../../../_metronic/partials/alert'
import mutations from './../../../../setup/graphql/mutations'
import { fetchStart, fetchStop, fetchStartWithForward } from '../../../../redux/actions/common'
import { setKycApplication, setKycSubStep, setKycEligibility, setKycStep } from '../redux/actions'
import { AlexiRoutes } from '../../../../utils/constants/AlexiRoutes.enum'
import { AlexiApplicationTypes } from '../../../../utils/constants/AlexiApplicationType.enum'

const Step1: FC = ({
  // prevStep,
  // nextStep,
  updateApplication,
  getApplication,
}: any) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const searchQuery: any = parse(history.location.search)

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [mobileNo, setMobileNo] = useState<any>('')
  // const [subStep, setSubStep] = useState(1);
  const [showOtp, setShowOtp] = useState(false)
  const loanSubStep = useSelector((state: any) => state.kycApplication.loanSubStep)
  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)
  const status = useSelector((state: any) => state.kycApplication.loanApplicationStatus)
  const entityPerson = useSelector((state: any) => state.kycApplication.entityPerson)

  const [createApplication, onSuccessCreateApplicaton] = useMutation(mutations.CREATE_APPLICATION)
  const [sendOTP, onSuccessSendOTP] = useMutation(mutations.SEND_OTP)
  const [validateOTP, onSucccessValidateOTP] = useMutation(mutations.VALIDATE_OTP)
  const [createLoanElgibility, onSuccessCreateLoanElgibility] = useMutation(
    mutations.CREATE_LOAN_ELIGIBILITY
  )
  const [updateLoanElgibility, onSuccessUpdateLoanElgibility] = useMutation(
    mutations.UPDATE_LOAN_ELIGIBILITY
  )

  /* useEffect(() => {
    if(status > 0) {
      const loanSubStep = status === 1 ? 1 : 0;
      dispatch(setKycSubStep(loanSubStep));
    } else {
      history.replace('/${AlexiRoutes.SG_ONBOARDING}/type')
    }

  }, [status]) */

  /* useEffect(() => {
    let subStep = 1;
    if (status === 2) subStep = 0
    if(loanApplication) {
      const currentStep = loanApplication.applicationCompleteStep;
      if(Math.floor(currentStep) === 1 ){
        subStep = Number(currentStep.toString().split('.')[1])+1;
      }
      setSubStep(subStep)
    }

  }, [loanApplication]) */

  useEffect(() => {
    searchQuery?.mobile && setMobileNo(searchQuery?.mobile)
  }, [searchQuery])

  useEffect(() => {
    if (loanApplication !== null) {
      setMobileNo(loanApplication.mobileNumber)
    }
  }, [loanApplication])

  useEffect(() => {
    if (onSuccessCreateApplicaton.called && !onSuccessCreateApplicaton.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateApplicaton.data) {
        const { createApplication } = onSuccessCreateApplicaton.data
        const mobileNo = createApplication.mobileNumber
        dispatch(setKycApplication(createApplication))
        /**
         * Resetting Mail Sent Flag on New Application Creation
         */
        localStorage.removeItem('errorLogged')
        // dispatch(fetchStop());
        setMobileNo(mobileNo)
        sendOTP({
          variables: {
            mobileNo,
            isApplicantOrShareholder: true,
          },
        })
        // localStorage.setItem('loanApplicationId', createApplication.id);
        // history.push(`/${AlexiRoutes.SG_ONBOARDING}/${createApplication.id}`)
        onSuccessCreateApplicaton.reset()
      } else {
      }

      if (onSuccessCreateApplicaton.error) {
        const { networkError } = onSuccessCreateApplicaton.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessCreateApplicaton.loading,
    onSuccessCreateApplicaton.data,
    onSuccessCreateApplicaton.error,
    onSuccessCreateApplicaton.called,
  ])

  useEffect(() => {
    if (onSuccessSendOTP.called && !onSuccessSendOTP.loading) {
      dispatch(fetchStop())
      if (onSuccessSendOTP.data) {
        if (onSuccessSendOTP.data.generateOtp.success) {
          dispatch(fetchStop())
          dispatch(setKycSubStep(2))
          setShowOtp(true)
          localStorage.removeItem('singpass')
          localStorage.setItem('loanApplicationId', onSuccessSendOTP.data.generateOtp.id)
          localStorage.setItem('loanType', onSuccessSendOTP.data.generateOtp.type)
          // history.push(`/${AlexiRoutes.SG_ONBOARDING}/${onSuccessSendOTP.data.generateOtp.id}`)
          onSuccessSendOTP.reset()
        } else {
          setShowAlert(true)
          // setAlertMsg('Error generating OTP. ');
          setAlertMsg('Error generating OTP.')
          setAlertMsg('Facing issue while sending OTP on this number')
          setAlertType('primary')
          dispatch(fetchStop())
        }
      } else {
        setShowAlert(true)
        // setAlertMsg('Unfortunately, we could not find an active application with this number');
        setAlertMsg('Facing issue while sending OTP on this number')
        setAlertType('primary')
        dispatch(fetchStop())
      }

      if (onSuccessSendOTP.error) {
        const { networkError } = onSuccessSendOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessSendOTP.loading,
    onSuccessSendOTP.data,
    onSuccessSendOTP.error,
    onSuccessSendOTP.called,
  ])

  useEffect(() => {
    if (onSucccessValidateOTP.called && !onSucccessValidateOTP.loading) {
      dispatch(fetchStop())
      if (onSucccessValidateOTP.data) {
        if (onSucccessValidateOTP.data.validateOtp.success) {
          if (loanApplication !== null) {
            if (loanApplication.applicationCompleteStep < 5) {
              const currentStep = loanApplication.applicationCompleteStep || 1
              const currentSubStep =
                currentStep >= 2 ? 0 : Number(currentStep.toString().split('.')[1])

              console.log('before Step:', currentStep)
              console.log('before subStep:', currentSubStep)

              let step, subStep
              if (status === 1) {
                step = currentStep < 1.4 ? Math.floor(currentStep) : Math.floor(currentStep) + 1
                subStep = currentStep < 1.4 ? (currentSubStep === 1 ? 3 : currentSubStep + 1) : 0
              } else {
                step =
                  currentStep < 1.4
                    ? Math.floor(currentStep)
                    : currentStep === 2
                    ? 2
                    : Math.floor(currentStep) + 1
                subStep = currentStep < 1.4 ? (currentSubStep === 1 ? 3 : currentSubStep + 1) : 0
              }

              if (
                step == 1 &&
                subStep == 4 &&
                loanApplication.applicationType == AlexiApplicationTypes.RECURRING_APPLICATION
              ) {
                step = 2
                subStep = 0
              }

              // if application type is tts or amz then force fully redirect to sing pass page
              if (
                loanApplication.applicationType === 'TTS' ||
                loanApplication.applicationType === 'AMZ'
              ) {
                step = 1
                subStep = 3
              }

              dispatch(setKycStep(step))
              dispatch(setKycSubStep(subStep))

              console.log('After Step:', step)
              console.log('After subStep:', subStep)

              history.push(
                `/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}?step=${step}${
                  subStep > 0 ? `.${subStep}` : ''
                }`
              )
              /* updateApplication({
                variables: {
                  data: { applicationCompleteStep: 1.2 },
                  where: { id: loanApplication.id }
                }
              }) */
            } else {
              dispatch(setKycStep(0))
              dispatch(setKycSubStep(1))
              history.push(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}`)
            }
          } else {
            getApplication({
              variables: {
                appId: localStorage.getItem('loanApplicationId'),
              },
            })

            // If we get mobile and id as querystring then redirect to singpass page
            if (searchQuery?.appId && searchQuery?.mobile) {
              dispatch(setKycStep(1))
              dispatch(setKycSubStep(3))

              history.push(`/${AlexiRoutes.SG_ONBOARDING}/${searchQuery?.appId}?step=1.3`)
            }
          }
          setShowOtp(false)
          // dispatch(setKycSubStep(3));
          onSucccessValidateOTP.reset()
        } else {
          const message = onSucccessValidateOTP.data.validateOtp.message
          setShowAlert(true)
          setAlertMsg(message)
          setAlertType('primary')
        }
      } else {
        setShowAlert(true)
        setAlertMsg('Unable to validate the OTP provided at the moment, please try again later!')
        setAlertType('primary')
        dispatch(fetchStop())
      }

      if (onSucccessValidateOTP.error) {
        const { networkError } = onSucccessValidateOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSucccessValidateOTP.loading,
    onSucccessValidateOTP.data,
    onSucccessValidateOTP.error,
    onSucccessValidateOTP.called,
  ])

  useEffect(() => {
    if (onSuccessCreateLoanElgibility.called && !onSuccessCreateLoanElgibility.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateLoanElgibility.data) {
        if (onSuccessCreateLoanElgibility.data) {
          const { createLoanEligibility } = onSuccessCreateLoanElgibility.data
          dispatch(setKycEligibility(createLoanEligibility))

          const debtRatio = calculateDebtRatio(createLoanEligibility)
          updateApplication({
            variables: {
              data: {
                ...(loanApplication.applicationCompleteStep < 1.3 && {
                  applicationCompleteStep: 1.3,
                }),
                debtRatio: debtRatio,
              },
              where: { id: loanApplication.id },
            },
          })
          dispatch(setKycSubStep(4))
          // dispatch(fetchStop());
          history.push(
            `/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}?${
              loanApplication.applicationType.toLowerCase() ==
              AlexiApplicationTypes.RECURRING_APPLICATION
                ? 'step=2'
                : 'step=1.4'
            }`
          )
          onSuccessCreateLoanElgibility.reset()
        }
      } else {
      }

      if (onSuccessCreateLoanElgibility.error) {
        const { networkError } = onSuccessCreateLoanElgibility.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessCreateLoanElgibility.loading,
    onSuccessCreateLoanElgibility.data,
    onSuccessCreateLoanElgibility.error,
    onSuccessCreateLoanElgibility.called,
  ])

  useEffect(() => {
    if (onSuccessUpdateLoanElgibility.called && !onSuccessUpdateLoanElgibility.loading) {
      dispatch(fetchStop())
      if (onSuccessUpdateLoanElgibility.data) {
        if (onSuccessUpdateLoanElgibility.data) {
          const { updateLoanEligibility } = onSuccessUpdateLoanElgibility.data
          dispatch(setKycEligibility(updateLoanEligibility))

          const debtRatio = calculateDebtRatio(updateLoanEligibility)

          updateApplication({
            variables: {
              data: {
                ...(loanApplication.applicationCompleteStep < 1.3 && {
                  applicationCompleteStep: 1.3,
                }),
                debtRatio: debtRatio,
              },
              where: { id: loanApplication.id },
            },
          })

          dispatch(setKycSubStep(4))
          // dispatch(fetchStop());
          history.push(
            `/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}?${
              loanApplication.applicationType.toLowerCase() ==
              AlexiApplicationTypes.RECURRING_APPLICATION
                ? 'step=2'
                : 'step=1.4'
            }`
          )
          onSuccessUpdateLoanElgibility.reset()
        }
      } else {
      }

      if (onSuccessUpdateLoanElgibility.error) {
        const { networkError } = onSuccessUpdateLoanElgibility.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessUpdateLoanElgibility.loading,
    onSuccessUpdateLoanElgibility.data,
    onSuccessUpdateLoanElgibility.error,
    onSuccessUpdateLoanElgibility.called,
  ])

  const calculateDebtRatio = (loanEligibility: any) => {
    const {
      // industryType,
      profit,
      totalMonthlyInstallmentsToBanks,
      totalMonthlyInstallmentsToNonBanks,
      totalRevolvingCreditOutstandingToBanks,
      totalRevolvingCreditOutstandingToNonBanks,
      dateOfIncorporation,
    } = loanEligibility

    const existingDebt =
      totalMonthlyInstallmentsToBanks +
      totalMonthlyInstallmentsToNonBanks +
      totalRevolvingCreditOutstandingToBanks * 0.05 +
      totalRevolvingCreditOutstandingToNonBanks * 0.05
    const netOperatingIncome = profit / 12
    const totalDebts = existingDebt + loanApplication.emi
    const debtRatio = (totalDebts / netOperatingIncome) * 100

    return debtRatio
  }

  const submitLoanComplete = () => {
    if (loanApplication.applicationCompleteStep < 1.4) {
      dispatch(fetchStartWithForward())
      updateApplication({
        variables: {
          data: { applicationCompleteStep: 1.4 },
          where: { id: loanApplication.id },
        },
      })
    }
    history.push(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}?step=2`)
    dispatch(setKycStep(2))
  }

  const backToLoanEligibility = () => {
    dispatch(setKycSubStep(3))
    history.replace(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}?step=1.3`)
  }

  const additionalProps: any = {
    // prevStep: prevStep,
    // nextStep: nextStep
  }

  const handleCloseOtp = () => {
    setShowOtp(false)
  }

  return (
    <>
      {loanSubStep === 0 && (
        <Step1Sub0 {...additionalProps} sendOTP={sendOTP} setMobileNo={setMobileNo} />
      )}
      {loanSubStep === 1 && (
        <Step1Sub1
          createApplication={createApplication}
          mobileNo={mobileNo}
          applicantName={loanApplication?.applicantName}
          sendOTP={sendOTP}
          onSuccessCreateApplicaton={onSuccessCreateApplicaton}
        />
      )}
      {loanSubStep === 2 && (
        <Step1Sub2
          sendOTP={sendOTP}
          validateOTP={validateOTP}
          mobileNo={mobileNo}
          setMobileNo={setMobileNo}
          show={showOtp}
          handleClose={handleCloseOtp}
        />
      )}
      {loanSubStep === 3 && (
        <Step1Sub3
          createLoanEligibility={createLoanElgibility}
          updateLoanElgibility={updateLoanElgibility}
          updateApplication={updateApplication}
        />
      )}
      {loanSubStep === 4 && (
        <Step1Sub4
          {...additionalProps}
          submitLoanComplete={submitLoanComplete}
          backToLoanEligibility={backToLoanEligibility}
        />
      )}

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </>
  )
}

export { Step1 }
