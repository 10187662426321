import { SVGProps } from 'react'
export function Step4Icon({
  color = 'var(--text-color-2)',
  fontSize = '24',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 30 24'
        fill='none'
        style={style}
        onClick={onClick}
        width={fontSize}
        height={fontSize}
      >
        <path
          d='M15.0042 0.707031C12.1627 0.707031 9.85032 3.11217 9.85032 6.06907C9.85032 8.97205 12.0285 11.3233 14.875 11.4186C14.9559 11.4104 15.0374 11.4104 15.1183 11.4186C15.1358 11.42 15.1534 11.4209 15.171 11.4211H15.1885C17.9636 11.3245 20.1468 8.97456 20.1581 6.07033C20.1581 3.11343 17.847 0.708285 15.0042 0.708285V0.707031ZM20.5155 14.4319C17.4871 12.329 12.5439 12.3265 9.49545 14.4319H9.49294C8.12108 15.3874 7.36618 16.6828 7.36869 18.0785C7.3712 19.4704 8.12609 20.7595 9.49419 21.7063C11.014 22.7646 13.0091 23.2938 15.0055 23.2938C17.0018 23.2938 18.9969 22.7646 20.5167 21.7063C21.8886 20.7495 22.6435 19.4554 22.641 18.0597C22.6385 16.6678 21.8836 15.3787 20.5155 14.4319ZM26.9309 6.73494C27.1039 8.92565 25.6092 10.8405 23.5313 11.1063L23.51 11.1088L23.4786 11.1113C23.4154 11.1058 23.3517 11.1118 23.2905 11.1289C22.2879 11.1861 21.3024 10.8506 20.5431 10.1934C21.6591 9.15889 22.2974 7.59643 22.1657 5.90731C22.0941 5.03058 21.8054 4.18547 21.3255 3.44824C21.7406 3.23507 22.2134 3.09336 22.7062 3.04822C24.853 2.8526 26.7428 4.50285 26.9309 6.73619V6.73494Z'
          fill={color}
        />
        <path
          d='M29.109 17.1767C29.0175 18.2677 28.3491 19.2157 27.2168 19.8615C26.1296 20.4885 24.7652 20.7719 23.4059 20.7393C24.1871 20.0057 24.6448 19.0953 24.7264 18.116C24.8329 16.7203 24.1947 15.3697 22.9118 14.3001C22.1643 13.6927 21.321 13.2139 20.4164 12.8831C22.819 12.1608 25.8286 12.6498 27.692 14.2035C28.6889 15.0424 29.1993 16.097 29.109 17.1767ZM3.06752 6.73103C2.89447 8.92174 4.38922 10.8366 6.46707 11.1024L6.48839 11.1049L6.51974 11.1074C6.58298 11.1019 6.6467 11.1079 6.70784 11.125C7.71045 11.1822 8.69594 10.8467 9.45532 10.1895C8.33927 9.15499 7.70099 7.59252 7.83266 5.9034C7.90915 4.98799 8.21262 4.14908 8.67283 3.44434C8.2423 3.22428 7.77368 3.0885 7.29219 3.04431C5.14537 2.84869 3.25561 4.49894 3.06752 6.73229V6.73103ZM0.890599 17.1767C0.982139 18.2677 1.65051 19.2157 2.78286 19.8615C3.87007 20.4885 5.2344 20.7719 6.59372 20.7393C5.81249 20.0057 5.35479 19.0953 5.27328 18.116C5.16669 16.7203 5.80497 15.3697 7.08779 14.3001C7.83534 13.6927 8.67865 13.2139 9.58322 12.8831C7.18059 12.1608 4.17102 12.6498 2.3076 14.2035C1.31068 15.0424 0.800312 16.097 0.890599 17.1767Z'
          fill={color}
        />
      </svg>
    </>
  )
}
