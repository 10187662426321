import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import NumberFormat from 'react-number-format'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { validateUEN } from '../../../../_metronic/helpers/validation'
import { fetchStartWithForward } from '../../../../redux/actions/common'
import { deleteApplicationDetails, saveApplicationDetails } from '../redux/actions'
import { trim } from '../../../../_metronic/helpers'
import { MaintenanceModeBanner } from '../../../../_metronic/helpers/components/MaintenanceModeBanner'
import { AlexiApplicationTypes } from '../../../../utils/constants/AlexiApplicationType.enum'
import { getStoredEnv } from '../../../../utils/getIntegrationURLFromENV'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'

const errorMsg = {
  required: 'required',
  amountRange: 'Amount range should be between 75,000 - 300,000',
  durationRange: 'Duration range should be between 3 - 12 months',
}

const Step1Sub1: FC<any> = ({ createApplication, mobileNo }) => {
  const dispatch = useDispatch()

  const maxAmount = 250000
  const minAmount = 75000
  const maxDuration = 12
  const minDuration = 3

  const [loanAmountRange, setLoanAmountRange] = useState<any>(75000)
  // const [loanDurationRange, setLoanDurationRange] = useState<any>(6);
  // const [mobileNo, setMobileNo] = useState<any>('+977984');
  // const [sendOTP, {data,loading,error}] = useMutation(mutations.SEND_OTP, { variables: { mobileNo, isApplicantOrShareholder: true } });

  const savedApplicationDetails = useSelector(
    (state: any) => state.partnerConnect.applicationDetails
  )
  const partnerDetails = useSelector((state: any) => state.partnerConnect.partner)

  const handleLoanAmountRange = (value: string) => {
    dispatch(saveApplicationDetails(formik.values))
    setLoanAmountRange(value)
    formik.setFieldValue('amountRequested', value)
    //if(error.match(/ name/i)) formik.setFieldError('name', error);
  }

  const handleLoanDurationRange = (value: string) => {
    dispatch(saveApplicationDetails(formik.values))
    // setLoanDurationRange(value);
    formik.setFieldValue('durationInMonths', value)
  }

  const initialValues = {
    applicantName: '',
    // applicationStatus: '',
    companyUEN: '',
    positionInCompany: '',
    amountRequested: loanAmountRange,
    // durationInMonths: loanDurationRange,
    mobileNumber: mobileNo ? mobileNo : '',
    promoCode: '',
    vendorCode: '',
  }

  const validationSchema = Yup.object().shape({
    amountRequested: Yup.number()
      .required(errorMsg.required)
      .min(minAmount, errorMsg.amountRange)
      .max(maxAmount, errorMsg.amountRange),
    // durationInMonths: Yup.number().required(errorMsg.required).min(minDuration, errorMsg.durationRange).max(maxDuration, errorMsg.durationRange),
    companyUEN: Yup.string()
      .required('Company UEN is required')
      .test('unique', 'Invalid UEN. Kindly recheck the entered UEN', (value: any) =>
        validateUEN(value)
      ),
    positionInCompany: Yup.string()
      .required('Position Within Company is required')
      .test('unique', 'Position must not contain numbers', (value: any) => {
        return !/\d/.test(value)
      }),
    applicantName: Yup.string()
      .required('Applicant Name is required')
      .test('unique', 'Applicant Name must not contain numbers', (value: any) => {
        return !/\d/.test(value)
      }),
    promoCode: Yup.string(),
    vendorCode: Yup.string(),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .test(
        'mobileValidation',
        'Invalid Mobile number. Kindly recheck the entered number',
        () => true
      )
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      }),
    //TODO: add validation on prod
    // ,(value:any) => {
    //   if(/^\+65(8|9)\d{7}$/g.test(value) || /^\+977\d{10}$/g.test(value)){
    //     return true
    //   }
    //   return false
    // })
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: savedApplicationDetails || initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const {
        amountRequested,
        companyUEN,
        positionInCompany,
        applicantName,
        mobileNumber,
        promoCode,
      } = trim(values)

      dispatch(fetchStartWithForward())

      createApplication({
        variables: {
          data: {
            amountRequested: amountRequested * 1,
            companyUEN,
            mibCompanyUEN: companyUEN,
            positionInCompany,
            applicantName,
            mobileNumber,
            promoCode,
            applicationStatus: 'in-progress',
            applicationCompleteStep: 1.1,
            applicationType: AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION,
            partnerId: partnerDetails?.id || '',
            credilinqEnvironment: getStoredEnv(),
          },
        },
      })

      if (localStorage.getItem('partnerApplicationType')) {
        localStorage.setItem('partnerApplicationType', '')
      }
      setSubmitting(false)
    },
  })

  useEffect(() => {
    return () => {
      dispatch(deleteApplicationDetails())
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <MaintenanceModeBanner />

      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Credit Line Details</h2>
            <hr className='text-gray-400' />
          </div>
          <div className='row mb-10'>
            <div className='col-md-12'>
              <label htmlFor='' className='input-field-label required'>
                Credit Line Required
              </label>

              <NumberFormat
                name='amountRequested'
                value={formik.values.amountRequested}
                className='form-control form-control-solid'
                // displayType={'text'}
                thousandSeparator={true}
                prefix={'SG$ '}
                onValueChange={(values: any) => {
                  let { value } = values
                  formik.setFieldValue('amountRequested', value)
                }}
                onBlur={(event: any) => {
                  let { value } = event.target
                  value = value.substring(4)
                  value = value ? parseFloat(value.replace(/,/g, '')) : 0
                  value = Math.round(value / 500) * 500
                  value = Math.min(maxAmount, Math.max(minAmount, value))
                  formik.setFieldValue('amountRequested', value)
                }}
              />
              {formik.touched.amountRequested && formik.errors.amountRequested && (
                <div className='text-danger mt-2'>{formik.errors.amountRequested}</div>
              )}

              <RangeSlider
                // id="amountRequested"
                min={minAmount}
                max={maxAmount}
                step={5000}
                tooltip='on'
                tooltipPlacement='top'
                tooltipLabel={(currentValue) => `SG$ ${currentValue}`}
                value={formik.values.amountRequested}
                onChange={(event: any) => {
                  handleLoanAmountRange(event.target.value)
                }}
                // onChange={({target:{value}}) => { if(regexNumber.test(value)) formik.setFieldValue('total', value) }}
                // onChange={formik.handleChange}
              />
              <div className='rangeSlider-labels'>
                <div>SG$ 75,000</div>
                <div>SG$ 250,000</div>
              </div>
            </div>

            <div className='sub-heading text-muted mb-10 mt-5'>
              If your credit line is approved, It will be valid for 1 year from the date of
              approval.
            </div>
          </div>
        </div>
        <div className='cardNew'>
          <div className='row'>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Company UEN/Number
              </label>
              <div className='col-sm-12'>
                <input
                  name='companyUEN'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.companyUEN}
                  onChange={formik.handleChange}
                />
                {formik.touched.companyUEN && formik.errors.companyUEN && (
                  <div className='text-danger mt-2'>{formik.errors.companyUEN}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Position within company
              </label>
              <div className='col-sm-12'>
                <input
                  name='positionInCompany'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.positionInCompany}
                  onChange={formik.handleChange}
                />
                {formik.touched.positionInCompany && formik.errors.positionInCompany && (
                  <div className='text-danger mt-2'>{formik.errors.positionInCompany}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Applicant Name
              </label>
              <div className='col-sm-12'>
                <input
                  name='applicantName'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.applicantName}
                  onChange={formik.handleChange}
                />
                {formik.touched.applicantName && formik.errors.applicantName && (
                  <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Mobile Number
              </label>
              <div className='col-sm-12'>
                <PhoneInput
                  id='mobileNumber'
                  international
                  defaultCountry='SG'
                  // countryCallingCodeEditable={false}
                  value={formik.values.mobileNumber}
                  onChange={(value) => formik.setFieldValue('mobileNumber', value)}
                  placeholder='Enter phone number'
                  className='int-phone-input'
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className='text-danger mt-2'>{formik.errors.mobileNumber}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='cardNew stickeyFooter'>
          <div className='d-flex justify-content-end'>
            <button type='submit' className='button button-primary '>
              Send OTP <RightAeroIcon color='currentColor' />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export { Step1Sub1 }
