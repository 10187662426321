/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ServerError, useMutation } from '@apollo/client'

import { Step1Sub0 } from './Step1Sub0'
import { Step1Sub1 } from './Step1Sub1'
import { Step1Sub2 } from './Step1Sub2'
import { Step1Sub3 } from './Step1Sub3'
import { Step1Sub4 } from './Step1Sub4'
import Alert from '../../../../_metronic/partials/alert'
import mutations from '../../../../setup/graphql/mutations'
import { fetchStart, fetchStop, fetchStartWithForward } from '../../../../redux/actions/common'
import {
  setPartnerApplication,
  setPartnerSubStep,
  setPartnerEligibility,
  setPartnerStep,
} from '../redux/actions'
import { AlexiApplicationTypes } from '../../../../utils/constants/AlexiApplicationType.enum'
import { setLoanEligibility } from '../../loanApplication/redux/actions'

const Step1: FC = ({
  // prevStep,
  // nextStep,
  updateApplication,
  getApplication,
}: any) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [mobileNo, setMobileNo] = useState<any>('')
  // const [subStep, setSubStep] = useState(1);
  const [showOtp, setShowOtp] = useState(false)

  const loanSubStep = useSelector((state: any) => state.partnerConnect.partnerSubStep)
  const partnerDetails = useSelector((state: any) => state.partnerConnect.partner)
  const loanApplication = useSelector((state: any) => state.partnerConnect.partnerApplication)
  const status = useSelector((state: any) => state.partnerConnect.partnerApplicationStatus)
  const entityPerson = useSelector((state: any) => state.partnerConnect.entityPerson)

  const [createApplication, onSuccessCreateApplicaton] = useMutation(mutations.CREATE_APPLICATION)
  const [sendOTP, onSuccessSendOTP] = useMutation(mutations.SEND_OTP)
  const [validateOTP, onSucccessValidateOTP] = useMutation(mutations.VALIDATE_OTP)
  const [createLoanElgibility, onSuccessCreateLoanElgibility] = useMutation(
    mutations.CREATE_LOAN_ELIGIBILITY
  )
  const [updateLoanElgibility, onSuccessUpdateLoanElgibility] = useMutation(
    mutations.UPDATE_LOAN_ELIGIBILITY
  )

  /* useEffect(() => {
    if(status > 0) {
      const loanSubStep = status === 1 ? 1 : 0;
      dispatch(setPartnerSubStep(loanSubStep));
    } else {
      history.replace('/application/type')
    }

  }, [status]) */

  /* useEffect(() => {
    let subStep = 1;
    if (status === 2) subStep = 0
    if(loanApplication) {
      const currentStep = loanApplication.applicationCompleteStep;
      if(Math.floor(currentStep) === 1 ){
        subStep = Number(currentStep.toString().split('.')[1])+1;
      }
      setSubStep(subStep)
    }

  }, [loanApplication]) */

  useEffect(() => {
    if (loanApplication !== null) {
      setMobileNo(loanApplication.mobileNumber)
    }
  }, [loanApplication])

  useEffect(() => {
    if (onSuccessCreateApplicaton.called && !onSuccessCreateApplicaton.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateApplicaton.data) {
        const { createApplication } = onSuccessCreateApplicaton.data
        const mobileNo = createApplication.mobileNumber
        dispatch(setPartnerApplication(createApplication))
        /**
         * Resetting Mail Sent Flag on New Application Creation
         */
        localStorage.removeItem('errorLogged')
        // dispatch(fetchStop());
        setMobileNo(mobileNo)
        sendOTP({
          variables: {
            mobileNo,
            isApplicantOrShareholder: true,
          },
        })
        // localStorage.setItem('loanApplicationId', createApplication.id);
        // history.push(`/application/${createApplication.id}`)
        onSuccessCreateApplicaton.reset()
      } else {
      }

      if (onSuccessCreateApplicaton.error) {
        const { networkError } = onSuccessCreateApplicaton.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessCreateApplicaton.loading,
    onSuccessCreateApplicaton.data,
    onSuccessCreateApplicaton.error,
    onSuccessCreateApplicaton.called,
  ])

  useEffect(() => {
    if (onSuccessSendOTP.called && !onSuccessSendOTP.loading) {
      dispatch(fetchStop())
      if (onSuccessSendOTP.data) {
        if (onSuccessSendOTP.data.generateOtp.success) {
          dispatch(fetchStop())
          dispatch(setPartnerSubStep(2))
          setShowOtp(true)
          localStorage.removeItem('singpass')
          localStorage.setItem('loanApplicationId', onSuccessSendOTP.data.generateOtp.id)
          localStorage.setItem('loanType', onSuccessSendOTP.data.generateOtp.type)
          history.push(
            `/partnerconnect/${partnerDetails?.id}/${onSuccessSendOTP.data.generateOtp.id}`
          )
          if (loanApplication && loanApplication?.companyUEN) {
            if (['stage', 'sandbox', 'dev'].includes(localStorage.getItem('env') as string)) {
              ;(async () => {
                const response = await import(
                  `../../../../utils/custom/${loanApplication.companyUEN}/step1Sub3.json`
                )
                const eligibilityData: any = response.default
                if (eligibilityData) {
                  // Dispatch the action to update the loan eligibility in the Redux store
                  dispatch(setPartnerEligibility(eligibilityData))
                }
              })()
            }

            localStorage.setItem('companyUEN', loanApplication.companyUEN)
          }
          onSuccessSendOTP.reset()
        } else {
          setShowAlert(true)
          // setAlertMsg('Error generating OTP. ');
          setAlertMsg('Error generating OTP.')
          setAlertMsg('Facing issue while sending OTP on this number')
          setAlertType('primary')
          dispatch(fetchStop())
        }
      } else {
        setShowAlert(true)
        // setAlertMsg('Unfortunately, we could not find an active application with this number');
        setAlertMsg('Facing issue while sending OTP on this number')
        setAlertType('primary')
        dispatch(fetchStop())
      }

      if (onSuccessSendOTP.error) {
        const { networkError } = onSuccessSendOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessSendOTP.loading,
    onSuccessSendOTP.data,
    onSuccessSendOTP.error,
    onSuccessSendOTP.called,
  ])

  useEffect(() => {
    if (onSucccessValidateOTP.called && !onSucccessValidateOTP.loading) {
      dispatch(fetchStop())
      if (onSucccessValidateOTP.data) {
        if (onSucccessValidateOTP.data.validateOtp.success) {
          if (loanApplication !== null) {
            if (loanApplication.applicationCompleteStep < 5) {
              const currentStep = loanApplication.applicationCompleteStep || 1
              const currentSubStep =
                currentStep >= 2 ? 0 : Number(currentStep.toString().split('.')[1])

              let step, subStep
              if (status === 1) {
                step = currentStep < 1.4 ? Math.floor(currentStep) : Math.floor(currentStep) + 1
                subStep = currentStep < 1.4 ? (currentSubStep === 1 ? 3 : currentSubStep + 1) : 0
              } else {
                step =
                  currentStep < 1.3
                    ? Math.floor(currentStep)
                    : currentStep === 2
                    ? 2
                    : Math.floor(currentStep) + 1
                subStep = currentStep < 1.3 ? (currentSubStep === 1 ? 3 : currentSubStep + 1) : 0
              }

              if (
                step == 1 &&
                subStep == 4 &&
                loanApplication.applicationType == AlexiApplicationTypes.RECURRING_APPLICATION
              ) {
                step = 2
                subStep = 0
              }
              dispatch(setPartnerStep(step))
              dispatch(setPartnerSubStep(subStep))

              history.push(
                `/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=${step}${
                  subStep > 0 ? `.${subStep}` : ''
                }`
              )
              /* updateApplication({
                variables: {
                  data: { applicationCompleteStep: 1.2 },
                  where: { id: loanApplication.id }
                }
              }) */
            } else {
              dispatch(setPartnerStep(0))
              dispatch(setPartnerSubStep(1))
              history.push(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}`)
            }
          } else {
            getApplication({
              variables: {
                appId: localStorage.getItem('loanApplicattionId'),
              },
            })
          }
          // dispatch(setPartnerSubStep(3));
          setShowOtp(false)

          onSucccessValidateOTP.reset()
        } else {
          const message = onSucccessValidateOTP.data.validateOtp.message
          setShowAlert(true)
          setAlertMsg(message)
          setAlertType('primary')
        }
      } else {
        setShowAlert(true)
        setAlertMsg('Unable to validate the OTP provided at the moment, please try again later!')
        setAlertType('primary')
        dispatch(fetchStop())
      }

      if (onSucccessValidateOTP.error) {
        const { networkError } = onSucccessValidateOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSucccessValidateOTP.loading,
    onSucccessValidateOTP.data,
    onSucccessValidateOTP.error,
    onSucccessValidateOTP.called,
  ])

  useEffect(() => {
    if (onSuccessCreateLoanElgibility.called && !onSuccessCreateLoanElgibility.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateLoanElgibility.data) {
        if (onSuccessCreateLoanElgibility.data) {
          const { createLoanEligibility } = onSuccessCreateLoanElgibility.data
          dispatch(setPartnerEligibility(createLoanEligibility))

          if (loanApplication.applicationCompleteStep < 1.3) {
            updateApplication({
              variables: {
                data: { applicationCompleteStep: 1.3 },
                where: { id: loanApplication.id },
              },
            })
          }
          // dispatch(setPartnerSubStep(4));
          // dispatch(setPartnerStep());
          // dispatch(fetchStop());
          // history.push(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?${loanApplication.applicationType.toLowerCase() == 'recurring' ? 'step=2' : 'step=1.4'}`);
          history.push(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=2`)
          dispatch(setPartnerStep(2))
          onSuccessCreateLoanElgibility.reset()
        }
      } else {
      }

      if (onSuccessCreateLoanElgibility.error) {
        const { networkError } = onSuccessCreateLoanElgibility.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessCreateLoanElgibility.loading,
    onSuccessCreateLoanElgibility.data,
    onSuccessCreateLoanElgibility.error,
    onSuccessCreateLoanElgibility.called,
  ])

  useEffect(() => {
    if (onSuccessUpdateLoanElgibility.called && !onSuccessUpdateLoanElgibility.loading) {
      dispatch(fetchStop())
      if (onSuccessUpdateLoanElgibility.data) {
        if (onSuccessUpdateLoanElgibility.data) {
          const { updateLoanEligibility } = onSuccessUpdateLoanElgibility.data
          dispatch(setPartnerEligibility(updateLoanEligibility))

          if (loanApplication.applicationCompleteStep < 1.3) {
            updateApplication({
              variables: {
                data: { applicationCompleteStep: 1.3 },
                where: { id: loanApplication.id },
              },
            })
          }
          // dispatch(setPartnerSubStep(4));
          // // dispatch(fetchStop());
          // history.push(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?${loanApplication.applicationType.toLowerCase() == 'recurring' ? 'step=2' : 'step=1.4'}`);
          history.push(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=2`)
          dispatch(setPartnerStep(2))
          onSuccessUpdateLoanElgibility.reset()
        }
      } else {
      }

      if (onSuccessUpdateLoanElgibility.error) {
        const { networkError } = onSuccessUpdateLoanElgibility.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessUpdateLoanElgibility.loading,
    onSuccessUpdateLoanElgibility.data,
    onSuccessUpdateLoanElgibility.error,
    onSuccessUpdateLoanElgibility.called,
  ])

  const submitLoanComplete = () => {
    if (loanApplication.applicationCompleteStep < 1.4) {
      dispatch(fetchStartWithForward())
      updateApplication({
        variables: {
          data: { applicationCompleteStep: 1.4 },
          where: { id: loanApplication.id },
        },
      })
    }
    history.push(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=2`)
    dispatch(setPartnerStep(2))
  }

  const backToLoanEligibility = () => {
    dispatch(setPartnerSubStep(3))
    history.replace(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=1.3`)
  }

  const additionalProps: any = {
    // prevStep: prevStep,
    // nextStep: nextStep
  }

  const handleCloseOtp = () => {
    setShowOtp(false)
  }

  return (
    <>
      {loanSubStep === 0 && (
        <Step1Sub0 {...additionalProps} sendOTP={sendOTP} setMobileNo={setMobileNo} />
      )}
      {loanSubStep === 1 && <Step1Sub1 createApplication={createApplication} mobileNo={mobileNo} />}
      {loanSubStep === 2 && (
        <>
          <Step1Sub2
            sendOTP={sendOTP}
            validateOTP={validateOTP}
            mobileNo={mobileNo}
            setMobileNo={setMobileNo}
            show={showOtp}
            handleClose={handleCloseOtp}
          />
          <Step1Sub1 createApplication={createApplication} mobileNo={mobileNo} />
        </>
      )}
      {loanSubStep === 3 && (
        <Step1Sub3
          createLoanEligibility={createLoanElgibility}
          updateLoanElgibility={updateLoanElgibility}
          updateApplication={updateApplication}
        />
      )}
      {loanSubStep === 4 && (
        <Step1Sub4
          {...additionalProps}
          submitLoanComplete={submitLoanComplete}
          backToLoanEligibility={backToLoanEligibility}
        />
      )}

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </>
  )
}

export { Step1 }
