import { SVGProps } from 'react'
export function EmailIcon({
  color = 'var(--text-color-2)',
  fontSize = '22',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fontSize}
      height={fontSize}
      viewBox='0 0 22 22'
      fill='none'
      style={style}
      onClick={onClick}
    >
      <path
        d='M5.1 4.5H17.9C18.78 4.5 19.5 5.22 19.5 6.1V15.7C19.5 16.58 18.78 17.3 17.9 17.3H5.1C4.22 17.3 3.5 16.58 3.5 15.7V6.1C3.5 5.22 4.22 4.5 5.1 4.5Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.5 6.10156L11.5 11.7016L3.5 6.10156'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
