import { SVGProps } from 'react'
export function Step2Icon({
  color = 'var(--text-color-2)',
  fontSize = '24',
  style = {},
  onClick,
}: SVGProps<SVGElement>) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 22 24'
        fill='none'
        width={fontSize}
        height={fontSize}
        style={style}
        onClick={onClick}
      >
        <path
          d='M14.596 7.2372C16.0657 7.39439 17.5441 7.45467 19.0217 7.41763L19.0182 7.4119C17.2732 4.77753 15.0198 2.51803 12.3902 0.765852C12.3521 2.2508 12.4124 3.73663 12.5707 5.21361C12.6836 6.18945 13.6197 7.12559 14.596 7.2372ZM16.7339 14.3028C15.8446 14.3028 14.9753 14.5665 14.2359 15.0605C13.4965 15.5546 12.9202 16.2568 12.5799 17.0784C12.2395 17.9 12.1505 18.8041 12.324 19.6763C12.4975 20.5484 12.9257 21.3496 13.5545 21.9784C14.1833 22.6072 14.9845 23.0355 15.8567 23.209C16.7289 23.3825 17.6329 23.2934 18.4545 22.9531C19.2761 22.6128 19.9783 22.0365 20.4724 21.2971C20.9665 20.5577 21.2302 19.6884 21.2302 18.7991C21.2302 17.6066 20.7565 16.4629 19.9132 15.6197C19.07 14.7765 17.9264 14.3028 16.7339 14.3028ZM18.8867 18.7144C18.8294 18.7717 18.7613 18.8172 18.6864 18.8483C18.6114 18.8793 18.5311 18.8953 18.45 18.8953C18.3689 18.8953 18.2886 18.8793 18.2136 18.8483C18.1387 18.8172 18.0706 18.7717 18.0132 18.7144L17.3515 18.0526V21.0318C17.3515 21.1956 17.2864 21.3527 17.1706 21.4685C17.0548 21.5843 16.8977 21.6494 16.7339 21.6494C16.5701 21.6494 16.413 21.5843 16.2972 21.4685C16.1813 21.3527 16.1163 21.1956 16.1163 21.0318V18.0548L15.4545 18.7166C15.3387 18.8324 15.1816 18.8975 15.0178 18.8975C14.854 18.8975 14.6969 18.8324 14.581 18.7166C14.4652 18.6007 14.4001 18.4436 14.4001 18.2798C14.4001 18.116 14.4652 17.9589 14.581 17.8431L16.2971 16.1274C16.3545 16.0701 16.4226 16.0246 16.4975 15.9935C16.5725 15.9625 16.6528 15.9465 16.7339 15.9465C16.815 15.9465 16.8953 15.9625 16.9703 15.9935C17.0452 16.0246 17.1133 16.0701 17.1706 16.1274L18.8867 17.8431C18.9441 17.9004 18.9896 17.9685 19.0206 18.0435C19.0517 18.1184 19.0677 18.1987 19.0677 18.2798C19.0677 18.3609 19.0517 18.4413 19.0206 18.5162C18.9896 18.5911 18.9441 18.6592 18.8867 18.7166V18.7144Z'
          fill={color}
        />
        <path
          d='M19.0632 8.59673C18.7694 8.60423 18.476 8.60909 18.184 8.60909C16.9436 8.6087 15.7041 8.54258 14.4707 8.41101H14.4601C13.7247 8.32586 13.0043 7.96103 12.4255 7.38399C11.8467 6.80696 11.4841 6.08478 11.3985 5.34981C11.3987 5.34628 11.3987 5.34275 11.3985 5.33922C11.2347 3.80611 11.1722 2.26385 11.2115 0.722503C8.89339 0.666631 6.57414 0.762124 4.26851 1.00837C2.72843 1.18704 1.25187 2.66404 1.07276 4.20412C0.669105 7.98264 0.669105 14.9988 1.07276 18.7773C1.25143 20.3174 2.72843 21.7944 4.26851 21.9731C6.88317 22.253 9.51498 22.3387 12.1423 22.2294C11.5858 21.4831 11.2187 20.6129 11.0727 19.6935C10.9267 18.774 11.0061 17.8329 11.3041 16.9509H5.14906C4.98526 16.9509 4.82817 16.8859 4.71234 16.77C4.59651 16.6542 4.53144 16.4971 4.53144 16.3333C4.53144 16.1695 4.59651 16.0124 4.71234 15.8966C4.82817 15.7808 4.98526 15.7157 5.14906 15.7157H11.8988C12.3893 14.9506 13.0529 14.3115 13.8359 13.85H5.14906C4.98526 13.85 4.82817 13.785 4.71234 13.6691C4.59651 13.5533 4.53144 13.3962 4.53144 13.2324C4.53144 13.0686 4.59651 12.9115 4.71234 12.7957C4.82817 12.6799 4.98526 12.6148 5.14906 12.6148H14.7222C14.886 12.6148 15.0431 12.6799 15.1589 12.7957C15.2747 12.9115 15.3398 13.0686 15.3398 13.2324V13.236C16.5889 12.9267 17.9054 13.0431 19.0808 13.5668C19.1148 11.9262 19.1086 10.2052 19.0632 8.59673ZM11.6358 10.7496H5.15083C4.98703 10.7496 4.82993 10.6845 4.7141 10.5687C4.59828 10.4529 4.53321 10.2958 4.53321 10.132C4.53321 9.96816 4.59828 9.81107 4.7141 9.69524C4.82993 9.57941 4.98703 9.51434 5.15083 9.51434H11.6358C11.7997 9.51434 11.9567 9.57941 12.0726 9.69524C12.1884 9.81107 12.2535 9.96816 12.2535 10.132C12.2535 10.2958 12.1884 10.4529 12.0726 10.5687C11.9567 10.6845 11.7997 10.7496 11.6358 10.7496Z'
          fill={color}
        />
      </svg>
    </>
  )
}
